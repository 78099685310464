import React, { Component } from 'react'

export class ResetPassword extends Component {
    render() {
        return (
            <div class="container">
                <div class="row">
                    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div class="card border-0 shadow rounded-3 my-5">
                            <div class="card-body p-4 p-sm-5">
                                <h5 class="card-title text-center mb-5 fw-light fs-5">Reset Password</h5>
                                <form>
                                    <div class="form-floating mb-3">
                                        <input type="password" class="form-control" id="ConfirmPassword" placeholder="ConfirmPassword" />
                                        <label for="ConfirmPassword">Confirm Password</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="password" class="form-control" id="RetypePassword" placeholder="RetypePassword" />
                                        <label for="RetypePassword">Re-Type Confirm Password</label>
                                    </div>
                                    <div class="d-grid">
                                        <button class="btn btn-primary btn-login text-uppercase fw-bold" type="submit">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResetPassword
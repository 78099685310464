import React, { Component } from 'react';
import useState from 'react';
import Latestupadtes from './Latestupdates';
import Featurecontent from './Featurecontent';
import SideMenu from '../Layout/SideMenu';
import LatestInnovations from '../Discover/LatestInnovations';
import { baseUrl, isLoading, apiKey } from '../../../src/Constants/Constants';
import style from './assets/css/upperContent.css';

class UpperContent extends Component {
    constructor(props) {
        super(props);
        isLoading(true);
        this.state = { latestUpdates: [], homeElement: {}, };
    }
    componentDidMount() {
        this.getData();
       
    }
    getData() {
        fetch(baseUrl + '/api/CCEPApiService/GetHomeElements', {
            headers: { 'XApiKey': apiKey }   
        })
            .then(response => response.json())
            .then(records => {
                if (records) {
                    this.setState({
                        homeElement: records
                    })
                }
                if (window.location.pathname && window.location.pathname.toLowerCase() == "/lab".toLowerCase()) {
                    setTimeout(() => {
                        let section = document.querySelector('#divNxtLabs');
                        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }, 1000);
                }
            })
            .catch(error => console.log(error))

    }
    render() {
        if (this.state.homeElement != null && this.state.homeElement.homeBannerElement != null) {
            return (
                <div>
                    <div className="overflow">
                        <div className="navigation">
                            <SideMenu />
                        </div>
                        <div className="body-section">
                            <div className="top-header-section">
                                <div className="row-contain">
                                    <div className="col-contain">
                                        <div className="home-logo">
                                            <img src={process.env.PUBLIC_URL + "./images/CCEP_NXT_Horizontal_White.png"} alt="CCEP" className="img-fluid" style={{ marginLeft: "-5px", marginTop:"-5px" }} />
                                        </div>
                                        <div className="banner-content" style={{ width:"85%" }}>
                                            {/* <img src="./images/nxt.svg" alt="" className="img-fluidMb" />*/}
                                            <p style={{ fontSize: "5rem" }}>{this.state.homeElement.homeBannerElement.title} </p>
                                            <p> {this.state.homeElement.homeBannerElement.subTitle}</p>
                                            <p className="text-content" style={{ fontSize: "1.5rem" }}>
                                                {this.state.homeElement.homeBannerElement.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-contain1" style={{ textAlign: "right" }}>
                                        <img src="./images/CCEP NXT X symbol_RGB_Option1.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            {/*{this.state.latestUpdates!=null && this.state.latestUpdates.length >0 && (*/}
                            {/*    <LatestInnovations folderData={this.state.latestUpdates} />*/}
                            {/*)}*/}
                            <Latestupadtes />
                            <Featurecontent homeElements={this.state.homeElement} />
                        </div>
                    </div>
                </div>

            );
        }
    }
}

export default UpperContent;
import React, { Component } from 'react'

export class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Email: '',
            Password: '',
            formErrors: {}
        };

        this.initialState = this.state;
    }
    handleFormValidation() {
       
        const { Email, Password } = this.state;
        let formErrors = {};
        let formIsValid = true;
        if (Email==="") {
            formIsValid = false;
            formErrors["Email"] = "Email id is required.";

        }
        else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email))) {
            formIsValid = false;
            formErrors["Email"] = "Invalid email id.";
        }
        if(Password==="")
        {
            formIsValid = false;
            formErrors["Password"] = "Password is required.";
        }
        this.setState({ formErrors: formErrors });
        
        return formIsValid;
    }
    handleChange = (e) => {
    
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    
    handleSubmit = (e) => {
        
        e.preventDefault();

        if (this.handleFormValidation()) {
            alert('You have been successfully registered.')
            this.setState(this.initialState)
        }
    }
    render() {
        const { Email, Password } = this.state.formErrors;
        return (
            <div class="container">
                <div class="row">
                    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div class="card border-0 shadow rounded-3 my-5">
                            <div class="card-body p-4 p-sm-5">
                                <h5 class="card-title text-center mb-5 fw-light fs-5">Login</h5>
                                <form onSubmit={this.handleSubmit}>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control" value={this.Email}
                                            onChange={this.handleChange} id="Email" name='Email' placeholder="name@example.com" /> 
                                        <div className='text-danger'>{Email}</div>
                                        <label htmlFor="Email">Email address</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="password" class="form-control" value={this.Password}
                                            onChange={this.handleChange} id="Password" name="Password" placeholder="Password" />
                                       <div className='text-danger'>{Password}</div>
                                        <label htmlFor="Password">Password</label>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md-4 d-flex justify-content-center">
                                            <div class="form-check mb-3 mb-md-0">
                                                <input class="form-check-input" type="checkbox" readOnly value="" id="loginCheck" checked />
                                                <label class="form-check-label" for="loginCheck"> Remember me </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4 d-flex justify-content-left">
                                            <a href="/ForgotPassword">Forgot password?</a>
                                        </div>
                                        <div class="col-md-4 d-flex justify-content-left">
                                            <a href="/UserRegister">Register</a>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button class="btn btn-primary btn-login text-uppercase fw-bold" type="submit">Sign
                                            in</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login
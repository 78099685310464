import React, { Component } from 'react';
import Slider from "react-slick";
import '../Stylesheet/css/mediacontent.css';
import ReactPlayer from 'react-player'
import PlayAgenda from '../Agenda/PlayAgenda';
import EventBus from 'eventing-bus';
import { baseUrl, apiKey } from '../../Constants/Constants';
import { AzureService } from "../../AzureService"
import { debounce } from "lodash";
import { Trash, TrashFill } from 'react-bootstrap-icons';

export default class LatestInnovations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resultData: this.props.folderData, maximumRecordShowCount: 1, rowcount: 1, showAgendaPopup: false,
            isShowHeader: true, playSelectedItemToAgenda: false, comeFrom: "", selectedMediaItem: null,
            agendaId: this.props.agendaId, dragStartIndex: null
        };
    }

    componentDidMount() {
        this.setStateValues(this.props);
    }


    componentWillReceiveProps(props) {
        this.setStateValues(props);
    }

    isShowAgendaPopup = (status) => {
        this.setState({ showAgendaPopup: true });
        if (AzureService.IsValidLogin()) {
            this.setState({ showAgendaPopup: status });
        }
        else {
            AzureService.LoginIfRequre();
        }

    };
    setStateValues(value) {
        var count = 0;
        if (value.folderData && value.folderData.length) {
            value.folderData.map((obj, index) => {
                count = (obj.mediaFiles != null && count > obj.mediaFiles.length) ? count : (obj.mediaFiles != null && obj.mediaFiles.length > 5) ? 5 : obj.mediaFiles != null ? obj.mediaFiles.length : count;
            });
        }
        this.setState({
            ...this.state, resultData: (value.folderData ? value.folderData : []),
            maximumRecordShowCount: count,
            rowcount: (count / 4),
            isShowHeader: value.isHideHeader === true ? false : true,
            playSelectedItemToAgenda: value.isPlaySelectedItem === true ? true : false,
            comeFrom: value.comeFrom
        }, () => {
            if (this.state.playSelectedItemToAgenda === true && value.stopReceiveProps !== true
                && this.state.resultData.length > 0 && this.state.resultData[0].mediaFiles != null
                && this.state.resultData[0].mediaFiles.length > 0) {
                EventBus.publish("selectedMediaContent", this.state.resultData[0].mediaFiles[0]);
                EventBus.unregisterCallback("selectedMediaContent");
            }
        });
    }

    mediaContentClick(pitem, item, isShowAgenda, ev) {
        if (this.state.playSelectedItemToAgenda === true) {
            this.props.selectedMediaContent(item);
        }
        else if (!this.state.playSelectedItemToAgenda && this.state.comeFrom !== "EDITAGENDA") {
            fetch(baseUrl + '/api/CCEPApiService/GetContentById', {
                method: 'POST',
                body: JSON.stringify([item.id]),
                headers: { 'Content-Type': 'application/json', 'XApiKey': apiKey }
            })
                .then(response => response.json())
                .then(records => {
                    if (records) {
                        this.setState({ selectedMediaItem: records && records.length > 0 ? records[0] : null, comeFrom: '' });
                        this.isShowAgendaPopup(isShowAgenda);
                    }
                })
                .catch(error => console.log(error))
        }
    }

    onPlayerReady(player) {
        this.player = player;
    }

    onEditAgendaCheckBoxChange(item, e) {
        this.props.getUpdatedItem(item);
        e.stopPropagation();
    }

    dragStart(eve, ind) {
        this.setState({ dragStartIndex: ind });
    }
    dragOver(eve) {
        eve.preventDefault();
    }

    drop(eve, pind, ind) {
        if (ind >= 0) {
            this.state.resultData[pind].mediaFiles
            this.state.resultData[pind].mediaFiles = this.arrangeOrder(this.state.resultData[pind].mediaFiles, this.state.dragStartIndex, ind);
            this.setState({
                ...this.state, resultData: this.state.resultData,
            }, () => {
                this.updateSorting(this.state.resultData[pind].mediaFiles);
            });
        }
    }

    arrangeOrder(array, from, to) {
        if (to === from) return array;
        var target = array[from];
        var increment = to < from ? -1 : 1;

        for (var k = from; k != to; k += increment) {
            array[k] = array[k + increment];
        }
        array[to] = target;
        return array;
    }

    updateSorting = debounce(function (list) {
        var postData = {
            agendaId: this.state.agendaId,
            attachmentOrder: []
        }
        if (this.state.agendaId > 0 && list != null && list.length) {
            list.map((obj, index) => {
                postData.attachmentOrder.push({ attachmentId: obj.id, fileUrl: obj.fileUrl, sortOrder: index, isUserMediaContent: obj.isUserMediaContent });
            })

            fetch(baseUrl + '/UpdateSortOrder', {
                method: 'POST',
                body: JSON.stringify(postData),
                headers: { 'Content-Type': 'application/json', 'XApiKey': apiKey }
            })
                .then(response => response.json())
                .then(records => {

                })
                .catch(error => console.log(error))
        }
    }, 1000);

    showHideIcon(pind, cind, isShow) {
        this.state.resultData[pind].mediaFiles[cind].isShowIcon = isShow;
        this.setState({ resultData: this.state.resultData });
    }

    render() {
        return (
            <div>
                {this.state.resultData != null && this.state.resultData.map((obj, index) => {
                    return (
                        <div style={{ marginTop: "1.5%" }} key={index + 1}>
                            {this.state.isShowHeader === true && (
                                <div class="d-flex justify-content-between">
                                    <div style={{ marginLeft: "1%" }} class="header-Style fs-3 lh-1 text-white font-bold">{obj.categoryName}</div>
                                </div>
                            )}
                            <Slider {...{
                                dots: true,
                                infinite: false,
                                touchThreshold: 100,
                                draggable: this.props.isDraggable == true ? false : true,
                                speed: 500,
                                slidesToShow: this.state.maximumRecordShowCount,
                                slidesToScroll: 5,
                                centerMode: false,
                                variableWidth: true,
                            }}>
                                {obj.mediaFiles != null && obj.mediaFiles.map((sobj, sindex) => {

                                    {
                                        return (
                                            <div draggable={this.props.isDraggable} id={"" + sindex} onDragOver={(e) => this.dragOver(e)} onDragStart={(e) => this.dragStart(e, sindex)} style={{marginLeft: "15px"}} 
                                                onDrop={(e) => this.drop(e, index, sindex)} className={this.state.comeFrom === "EDITAGENDA" ? "div-remove-selector" : ""} key={sindex + 1}
                                                onClick={() => this.mediaContentClick(obj, sobj, true, this)} onMouseEnter={() => this.state.comeFrom === "EDITAGENDA" && this.showHideIcon(index, sindex, true)} onMouseLeave={() => this.state.comeFrom === "EDITAGENDA" && this.showHideIcon(index, sindex, false)}>
                                                {this.state.comeFrom === "EDITAGENDA" && (
                                                    <input title={sobj.isSelected == true ? 'Click here to hide' : 'Click here to show'} type="checkbox" value={sobj.isSelected} checked={sobj.isSelected} className="top-right-icon-p" onChange={(e) => this.onEditAgendaCheckBoxChange(sobj, e)} />
                                                )}
                                                {this.state.comeFrom === "EDITAGENDA" && sobj.isShowIcon == true && (
                                                    <TrashFill title="Delete" fontSize="20" style={{ color: "red" }} onClick={(e) => this.props.deleteAgenda(sobj)} className="top-left-icon-p" />
                                                )}
                                                {(this.state.comeFrom === "DISCOVER" || this.state.comeFrom === "PLAYAGENDA" || this.state.comeFrom === "PLAYAGENDACONTENT" || this.state.comeFrom == "EDITAGENDA") && sobj.thumbnailUrl !== 'undefined' && (

                                                    <img src={sobj.thumbnailUrl} style={{ pointerEvents: "none", borderRadius: "5px", objectFit: "cover", textAlign: "left", marginLeft: "7%", marginRight: "10px"  }} />
                                                )}
                                                {(this.state.comeFrom !== "DISCOVER" && this.state.comeFrom !== "PLAYAGENDA" && this.state.comeFrom !== "PLAYAGENDACONTENT" && this.state.comeFrom != "EDITAGENDA") && (
                                                    <img src={sobj.fileUrl} style={{ pointerEvents: "none", borderRadius: "5px", objectFit: "cover", textAlign: "left", marginLeft: "7%", marginRight: "10px" }} />
                                                )}

                                                {this.state.isShowHeader === true && (
                                                    <div class="fs-6 text-white font-bold lh-base mt-2" style={{ textAlign: "center", marginLeft: "7%", marginRight: "10px" }}>{sobj.fileName}</div>
                                                )}
                                                {(this.state.comeFrom === "DISCOVER" || this.state.comeFrom === "PLAYAGENDA" || this.state.comeFrom === "PLAYAGENDACONTENT") && sobj.thumbnailUrl !== 'undefined' && (

                                                    <div style={{ height: '100px', marginTop: '5px' }} class='class="fs-6 text-white lh-base mt-2'>{sobj.title}</div>
                                                )}
 
                                            </div>
                                        )
                                    }

                                })}
                            </Slider>
                        </div>
                    )
                })}
                {this.state.showAgendaPopup === true && (
                    <PlayAgenda showAgendaPopup={this.state.showAgendaPopup} onPopupClose={this.isShowAgendaPopup}
                        selectedMediaItem={this.state.selectedMediaItem} openFrom={'DISCOVER'}></PlayAgenda>
                )}

            </div>
        );

    }
}

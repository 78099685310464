import React, { Component } from 'react';
import '../Stylesheet/css/mediacontent.css';
import ReactPlayer from 'react-player'
import EventBus from 'eventing-bus';
import { baseUrl, apiKey } from '../../Constants/Constants';
import { debounce } from "lodash";

export default class DisplayContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resultData: this.props.folderData, maximumRecordShowCount: 1, rowcount: 1, showAgendaPopup: false,
            isShowHeader: true, playSelectedItemToAgenda: false, comeFrom: "", selectedMediaItem: null,
            agendaId: this.props.agendaId, dragStartIndex: null
        };
    }

    componentDidMount() {
        this.setStateValues(this.props);
    }

    setStateValues(value) {
        var count = 0;
        if (value.folderData && value.folderData.length) {
            value.folderData.map((obj, index) => {
                count = (obj.mediaFiles != null && count > obj.mediaFiles.length) ? count : (obj.mediaFiles != null && obj.mediaFiles.length > 5) ? 5 : obj.mediaFiles != null ? obj.mediaFiles.length : count;
            });
        }
        this.setState({
            ...this.state, resultData: (value.folderData ? value.folderData : []),
            maximumRecordShowCount: count,
            rowcount: (count / 4),
            isShowHeader: value.isHideHeader === true ? false : true,
            playSelectedItemToAgenda: value.isPlaySelectedItem === true ? true : false,
            comeFrom: value.comeFrom
        }, () => {
            
        });
    }


    dragStart(eve, ind) {
        this.setState({ dragStartIndex: ind });
    }

    dragOver(eve) {
        eve.preventDefault();
    }

    drop(eve, pind, ind) {
        if (ind >= 0) {
            this.state.resultData[pind].mediaFiles
            this.state.resultData[pind].mediaFiles = this.arrangeOrder(this.state.resultData[pind].mediaFiles, this.state.dragStartIndex, ind);
            this.setState({
                ...this.state, resultData: this.state.resultData,
            }, () => {
                this.updateSorting(this.state.resultData[pind].mediaFiles);
            });
        }
    }

    arrangeOrder(array, from, to) {
        if (to === from) return array;
        var target = array[from];
        var increment = to < from ? -1 : 1;

        for (var k = from; k != to; k += increment) {
            array[k] = array[k + increment];
        }
        array[to] = target;
        return array;
    }

    updateSorting = debounce(function (list) {
        var postData = {
            agendaId: this.state.agendaId,
            attachmentOrder: []
        }
        if (this.state.agendaId > 0 && list != null && list.length) {
            list.map((obj, index) => {
                postData.attachmentOrder.push({ attachmentId: obj.id, fileUrl: obj.fileUrl,sortOrder: index });
            })

            fetch(baseUrl + '/UpdateSortOrder', {
                method: 'POST',
                body: JSON.stringify(postData),
                headers: { 'Content-Type': 'application/json', 'XApiKey': apiKey }
            })
                .then(response => response.json())
                .then(records => {

                })
                .catch(error => console.log(error))
        }
    }, 1000);

    render() {
        return (
            <div>
                {this.state.resultData != null && this.state.resultData.map((obj, index) => {
                    return (
                        <div style={{ marginTop: "1.5%" }} key={index + 1}>
                            <div className="row" style={{ paddingBottom: "40px", paddingTop: "40px" }} >
                                {obj.mediaFiles != null && obj.mediaFiles.map((sobj, sindex) => {                                  
                                    return (   
                                        <div title={sobj.title} draggable={this.props.isDraggable} id={"" + sindex} onDragOver={(e) => this.dragOver(e)} onDragStart={(e) => this.dragStart(e, sindex)}
                                                onDrop={(e) => this.drop(e, index, sindex)} key={sindex + 1} style={{
                                                    height: "160px", overflow: "hidden", width: '10%', float: "left",
                                                    marginTop: (sindex > 8 ? "1%" : "0%"), marginLeft: (sindex > 0 && sindex != 9 && sindex != 18 && sindex != 27 && sindex != 36 && sindex != 45 && sindex != 54 && sindex != 63 && sindex != 72 ? "1%" : "0%")
                                            }} >
                                              <div>
                                                  <img src={sobj.thumbnailUrl} title={sobj.title} style={{ pointerEvents: "none", borderRadius: "5px", objectFit: "cover", width: "100%", height: "100%" }} />
                                              </div>
                                               <div style={{ height: '100px', marginTop: '5px' }} class='class="fs-6 text-white font-bold lh-base mt-2'>{sobj.title}</div>
                                            </div>
                                        )  
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        );

    }
}

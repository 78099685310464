import React, { Component } from 'react'
import LatestInnovations from './LatestInnovations'
import '../Stylesheet/css/mediacontent.css'
import Layout, { LayOutContext } from '../Layout/Layout';
import { discoveryFilters, sortByFilters, baseUrl, isLoading, apiKey } from '../../Constants/Constants';


export default class DiscoverContent extends Component {
    constructor(props) {
        super(props);
        isLoading(true);
        this.state = {
            categories: [], selectedFolderItem: [], activeFolderId: [], headerSearchText: '',
            headerMediaType: null, headerSortOrder: null,filterType:"0", selectedFIleId : null
        };
       
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let categoryId = params.get('categoryId');
        let fileId = params.get('fileId');
        if (categoryId && parseFloat(categoryId) && parseFloat(fileId)) {
            this.setState({
                ...this.state, activeFolderId: [parseFloat(categoryId)],
                selectedFileId: parseFloat(fileId)
            }, () => {
                this.getData(this.state.activeFolderId);
            });
        }
        else {
            this.getData();
        }
    }


    static contextType = LayOutContext;

    componentWillReceiveProps(props) {
        if (LayOutContext && LayOutContext._currentValue && (this.isValueChanged(this.state.headerSearchText, LayOutContext._currentValue.headerSearchText)
            || this.isValueChanged(this.state.headerMediaType, LayOutContext._currentValue.headerMediaType))) {
            this.setState({
                ...this.state, headerSearchText: (LayOutContext && LayOutContext._currentValue ? LayOutContext._currentValue.headerSearchText : ''),
                headerMediaType: (LayOutContext && LayOutContext._currentValue ? LayOutContext._currentValue.headerMediaType : '')
            }, () => {
                this.getData(this.state.activeFolderId, [], this.state.headerSearchText, this.state.headerMediaType, this.state.headerSortOrder, this.state.filterType);
            });
        }
    }

    isValueChanged(oldValue, newValue) {
        return (!oldValue && newValue) || (oldValue && !newValue) || (oldValue && newValue && oldValue != newValue)
    }
    changeSortByFilter(evt) {
        this.setState({
            ...this.state, headerSortOrder: evt.target.value
        }, () => {
            this.getData(this.state.activeFolderId, [], this.state.headerSearchText, this.state.headerMediaType, this.state.headerSortOrder, this.state.filterType);
        });
    }

    changeFilterType(evt) {
        this.setState({
            ...this.state, filterType: evt.target.value
        }, () => {
            this.getData(this.state.activeFolderId, [], this.state.headerSearchText,
                this.state.headerMediaType, this.state.headerSortOrder, this.state.filterType);
        });
    }

    getData(catIds = [], catNames = [], searchText = "", mediaType = "", sortOrder = "0",filterType="0", isLatestInnovation = false) {
        let todo = {
            "categoryId": catIds,
            "categoryName": catNames,
            "isLatestInnovation": isLatestInnovation,
            "searchBy": "",
            "searchText": searchText,
            "mediaType": (!mediaType || mediaType == "all") ? "" : mediaType,
            "sortOrder": !sortOrder ? "0" : sortOrder,
            "filterType": filterType
        };
        fetch(baseUrl+'/api/CCEPApiService/GetMediaContentsByCategory', {
            method: 'POST',
            body: JSON.stringify(todo),
            headers: { 'Content-Type': 'application/json', 'xapikey': apiKey }
        })
            .then(response => response.json())
            .then(records => {
                if (records) {
                    
                    if (records.mediaContents && records.mediaContents.length && this.state.selectedFileId) {
                        records.mediaContents.map((obj, index) => {
                            if (obj && obj.mediaFiles && obj.mediaFiles.length) {
                                obj.mediaFiles = obj.mediaFiles.filter(item => item.id === this.state.selectedFileId);
                            }
                        });
                    }
                    
                    this.getActiveFolderData(records.mediaContents, records.categories);
                }
                isLoading(false);
            })
            .catch(error => {
                isLoading(false);
                console.log(error)
            })
    }


    getActiveFolderData(list, categoryList) {
        if (!this.state.categories || !this.state.categories.length) {
            categoryList.map((obj, index) => {
                obj.isActive = false;
            })
            this.setState({
                ...this.state, categories: categoryList,
                selectedFolderItem: list
            });
        }
        else {
            this.setState({
                ...this.state, selectedFolderItem: list
            });
        }
    }

    getSelectedFolderFile(item) {
        var existFolder = this.state.activeFolderId.filter(category => {
            return item.categoryId == category;
        });
        if (existFolder && existFolder.length) {
            existFolder = this.state.activeFolderId.filter(category => {
                return item.categoryId != category;
            });
        }
        else {
            existFolder = [item.categoryId].concat(this.state.activeFolderId);
        }

        this.state.categories.map((obj, index) => {
            var existList = existFolder.filter(item => obj.categoryId == item);
            obj.isActive = (existList && existList.length) ? true : false;
        })

        this.setState({
            ...this.state, activeFolderId: existFolder,
        }, () => {
            this.getData(this.state.activeFolderId, [], this.state.headerSearchText, this.state.headerMediaType, this.state.headerSortOrder, this.state.filterType);
        });
    }

    render() {
        return (
            <div>
                {this.state.categories && this.state.categories.length && (
                    <ul className="list-unstyled list-inline suggestion-section d-flex flex-wrap pt-2 px-3 mb-2">
                        {this.state.categories.map((obj, index) => {
                            return (
                                <li key={index + 1} className="mx-2 mt-2" onClick={() => this.getSelectedFolderFile(obj)}><a className={obj.isActive ? "d-block active active-folder" : "d-block folder-bc"}>{obj.categoryName}</a></li>
                            )
                        })}
                    </ul>
                )}
                <div style={{ marginTop: "15px" }} class="d-flex align-items-center justify-content-between" style={{ marginTop: "30px", marginBottom: "25px" }}>
                    <div class="discovery-filter fs-5 lh-1" style={{ marginLeft: "1%" }}>
                        <select id="sltCategorySortOrder" style={{ backgroundColor: "#333333", color: "white", border: "groove", hight: "30px" }}
                            onChange={this.changeSortByFilter.bind(this)}                        >
                            <option value="0">Sort by</option>
                            {sortByFilters != null && sortByFilters.map((obj, index) => {
                                return (<option key={index + 1} value={obj.id} >{obj.name}</option>)
                            })}
                        </select>
                    </div>
                    {/*<div class="see-all-Style fs-5" style={{ marginRight: "1%" }}>*/}
                    {/*    <select id="sltCategoryFilter" style={{ backgroundColor: "#333333", color: "white", border: "groove", hight: "30px" }}*/}
                    {/*        onChange={this.changeFilterType.bind(this)}                        >*/}
                    {/*        <option value="0">Filter by</option>*/}
                    {/*        {discoveryFilters != null && discoveryFilters.map((obj, index) => {*/}
                    {/*            return (<option key={index + 1} value={obj.id} >{obj.name}</option>)*/}
                    {/*        })}*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                </div>
                {this.state.selectedFolderItem && this.state.selectedFolderItem.length && (
                    <LatestInnovations folderData={this.state.selectedFolderItem} />
                )}
            </div>
        )
    }
}


import '../Stylesheet/css/styles.css';
import Layout from '../Layout/Layout'
import React, { Component, Fragment } from 'react';
import LatestInnovations from '../Discover/LatestInnovations';
import CreateAgenda from './CreateAgenda';
import ReactPlayer from 'react-player';
import { PencilSquare, ArrowReturnLeft, Download } from 'react-bootstrap-icons';
import { baseUrl, isLoading, apiKey } from '../../Constants/Constants';
import fileDownload from 'js-file-download';

export default class PlayAgendaContent extends Component {
    constructor(props) {
        super(props);
        isLoading(true);
        this.state = {
            mediaContents: [],
            selectedMediaItem: null,
            selectedContent: null,
            isStopReceiveProps: false,
            agendaId: null,
            showEditAgenda: false,
            agendaData: null,
            mediaList: []
        };
    }

    componentDidMount() {
        let search = window.location.search
        let params = new URLSearchParams(search);
        this.setState({
            ...this.state, agendaId: params.get('agendaId'),
        }, () => {
            this.getAgendaMediaFiles();
        });
    }

    getAgendaMediaFiles() {
        if (this.state.agendaId && this.state.agendaId > 0) {
            fetch(baseUrl + '/GetPlayAgenda?id=' + this.state.agendaId,
                {
                    headers: { 'XApiKey': apiKey }
                })
                .then(response => response.json())
                .then(records => {
                    if (records) {
                        if (records.mediaContent != null && records.mediaContent.mediaFiles != null
                            && records.mediaContent.mediaFiles.length > 0) {
                            records.mediaContent.mediaFiles[0].isSelectedAttachment = true;
                        }
                        this.setState({
                            agendaData: records,
                            selectedContent: records.mediaContent,
                            mediaContents: records.mediaContent != null ? [this.getActiveMediaOnly(JSON.stringify(records.mediaContent))] : [],
                            mediaList: records.mediaContent != null ? [records.mediaContent] : [],
                            selectedMediaItem: records.mediaContent != null && records.mediaContent.mediaFiles != null
                                && records.mediaContent.mediaFiles.length > 0 ? records.mediaContent.mediaFiles[0] : null
                        });
                    }
                    isLoading(false);
                })
                .catch(error => { isLoading(false); console.log(error) })
        }
    }

    getActiveMediaOnly(mediaContent) {
        mediaContent = JSON.parse(mediaContent);
        if (mediaContent.mediaFiles != null && mediaContent.mediaFiles.length) {
            mediaContent.mediaFiles = mediaContent.mediaFiles.filter(item => item.isActive == 1);
        }
        return mediaContent;
    }

    getSelectedMedia = (item) => {
        if (this.state.selectedContent != null && this.state.selectedContent.mediaFiles != null && this.state.selectedContent.mediaFiles.length > 0) {
            this.state.selectedContent.mediaFiles.filter(f => (f.id == item.id) || (f.isSelectedAttachment == true)).map((sobj, sindex) => {
                sobj.isSelectedAttachment = false;
                if (sobj.id === item.id) {
                    sobj.isSelectedAttachment = true;
                }
            })
        }
        this.setState({ selectedContent: this.state.selectedContent, selectedMediaItem: item, isStopReceiveProps: true });
    };

    backToAgenda = () => {
        window.location = `Agenda`;
    };

    isShowEditAgendaPopup = (status) => {
        this.setState({ showEditAgenda: status });
    }

    UpdateMediaContent = (data) => {
        if (data != null) {
            if (data.responseData != null) {
                this.state.agendaData.agendaName = data.responseData.agendaName;
                this.state.agendaData.agendaDescription = data.responseData.agendaDescription;
            }
            this.setState({ agendaData: this.state.agendaData });

            var media = data.mediaList;
            if (this.state.selectedContent != null && this.state.selectedContent.mediaFiles) {
                this.state.selectedContent.mediaFiles.map((sobj, index) => {
                    sobj.isActive = 0;
                    if (media.length > 0 && media[0].mediaFiles != null && media[0].mediaFiles.filter(item => item.id == sobj.id).length) {
                        sobj.isActive = 1;
                    }
                })
            }
            this.setState({ selectedContent: this.state.selectedContent, mediaContents: media });
            if (media != null) {
                media.map((obj, index) => {
                    if (obj.mediaFiles != null && obj.mediaFiles.length > 0) {
                        var existItem = obj.mediaFiles.filter(item => item.id === this.state.selectedMediaItem.id);
                        if (existItem == null || existItem.length == 0) {
                            this.setState({ selectedMediaItem: obj.mediaFiles[0] });
                        }
                    }
                })
            }
        }
    }

    downloadMediaContent = (item) => {
        fetch(baseUrl + '/Download?url=' + item.fileUrl,
            {
                headers: { 'XApiKey': apiKey }
            })
            .then(response => response.json())
            .then(buffer => {
                if (buffer) {
                    const nameSplit = item.fileUrl.split("/");
                    const duplicateName = nameSplit.pop();
                    fileDownload(Buffer.from(buffer, "base64"), duplicateName);
                }
            })
            .catch(error => console.log(error))
    };
    render() {
        let currentSelectedItem = this.state.selectedMediaItem;
        const isContentExist = (this.state.mediaContents != null && this.state.mediaContents.length > 0
            && this.state.mediaContents[0].mediaFiles != null && this.state.mediaContents[0].mediaFiles.length > 0);

        return (
            <Fragment>
                <Layout>
                    <div className="modal-content">
                        <div style={{ textAlign: "right", padding: "10px", backgroundColor: "#191919" }}>
                            <button style={{ backgroundColor: "#404040", color: "white", cursor: "pointer" }} onClick={() => this.isShowEditAgendaPopup(true)} type="button" className="btn btn-white play-agenda-btn" >
                                <PencilSquare fontSize="20" />
                                <span className="ps-3 pe-3">Edit Agenda</span>
                            </button>
                            <button style={{ backgroundColor: "#404040", color: "white", marginLeft: "5px", cursor: "pointer" }} type="button" className="btn btn-white" onClick={() => this.backToAgenda()} >
                                <ArrowReturnLeft fontSize="20" />
                                <span className="ps-2 pe-3">Back To Agenda's</span>
                            </button>
                            <button style={{ backgroundColor: "#404040", color: "white", marginLeft: "5px", cursor: "pointer", paddingRight: "10px" }} type="button" className="btn btn-white" onClick={() => currentSelectedItem != null && currentSelectedItem.fileUrl &&  this.downloadMediaContent(currentSelectedItem)} >
                                <Download fontSize="20" />
                            </button>
                        </div>
                        <div className="modal-body p-0 rounded">
                            <div className="play-agenda-h" style={{ margin: "5px", height: "650px" }}>
                                {(currentSelectedItem != null && ((currentSelectedItem.contentType == "File" || currentSelectedItem.contentType == "xlsxdocument") && ((currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "xls" || (currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "xlsx")) ?
                                    (
                                        <iframe className={(currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1))} width="100%" height="100%" frameborder="0" src={'https://view.officeapps.live.com/op/embed.aspx?src=' + currentSelectedItem.fileUrl}></iframe>
                                    )
                                    :
                                    currentSelectedItem != null && ((currentSelectedItem.contentType == "umbracoMediaArticle"  || currentSelectedItem.contentType == "docxdocument") && ((currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "doc" || (currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "docx")) ?
                                        (
                                            <iframe className={(currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1))} width="100%" height="100%" frameborder="0" src={'https://view.officeapps.live.com/op/embed.aspx?src=' + currentSelectedItem.fileUrl}></iframe>
                                        )
                                        :
                                        currentSelectedItem != null && ((currentSelectedItem.contentType == "File" || currentSelectedItem.contentType == "pptxdocument") && ((currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "ppt" || (currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "pptx")) ?
                                            (
                                                <iframe className={(currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1))} width="100%" height="100%" frameborder="0" src={'https://view.officeapps.live.com/op/embed.aspx?src=' + currentSelectedItem.fileUrl}></iframe>
                                            )
                                           :
                                            currentSelectedItem != null && ((currentSelectedItem.contentType == "umbracoMediaArticle" || currentSelectedItem.contentType == "pdfdocument") || currentSelectedItem.contentType == "File" && ((currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "pdf")) ?
                                                (
                                                   /* <object data={currentSelectedItem.fileUrl} width="100%" height="100%" style={{ backgroundColor: "white" }}></object>*/
                                                     <iframe className={(currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1))} width="100%" height="100%" frameborder="0" src={`https://docs.google.com/gview?url=${currentSelectedItem.fileUrl}&embedded=true`} ></iframe>
                                                ) :
                                            currentSelectedItem != null && currentSelectedItem.contentType == "umbracoMediaArticle" ?
                                                (
                                                    <object data={currentSelectedItem.fileUrl} width="100%" height="100%" style={{ backgroundColor: "white" }}></object>
                                                ) :
                                                currentSelectedItem != null && (currentSelectedItem.contentType == "umbracoMediaVideo" || currentSelectedItem.contentType == "mediavideo") ?
                                                    (
                                                        <ReactPlayer controls={true} width='100%' height='100%' url={currentSelectedItem.fileUrl} className="video-content" />
                                                    ) :
                                                        currentSelectedItem != null && currentSelectedItem.contentType == "Url" ?
                                                            (
                                                                <iframe controls={true} width='100%' height='100%' src={currentSelectedItem.fileUrl} />
                                                            ) :
                                                            currentSelectedItem != null && (currentSelectedItem.contentType == "contentPage" || currentSelectedItem.contentType == "Image") ?
                                                                (
                                                                    <img width="100%" height="100%" src={currentSelectedItem.fileUrl} />
                                                                ) :
                                                                currentSelectedItem != null ?
                                                                    (
                                                                        <iframe controls={true} width='100%' height='100%' src={currentSelectedItem.fileUrl} />
                                                                    )
                                                                    : (
                                                                        <img src="./images/video.png" alt="" width="100%" height="100%" />
                                                                    )
                                )}
                            </div>
                            {isContentExist == true && (
                                <div className="media-contents-h" style={{ paddingLeft: "20px", paddingRight: "30px", marginTop: "37px" }}>
                                    <LatestInnovations folderData={this.state.mediaContents} isHideHeader={true} isDraggable={true} comeFrom={'PLAYAGENDACONTENT'} agendaId={this.state.agendaId}
                                        isPlaySelectedItem={true} selectedMediaContent={this.getSelectedMedia} stopReceiveProps={this.state.isStopReceiveProps} />
                                </div>
                            )}
                        </div>

                        {this.state != null && this.state.showEditAgenda == true && (
                            <CreateAgenda showcreatePopup={this.state.showEditAgenda} onPopupClose={this.isShowEditAgendaPopup} editData={this.state.agendaData}
                                mediaList={this.state.mediaList} popUpSize={'lg'} editMediaContent={this.state.selectedContent}
                                getUpdateMediaContent={this.UpdateMediaContent} ></CreateAgenda>
                        )}
                    </div>
                </Layout >
            </Fragment >
        )
    }
}


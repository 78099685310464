import React, { Component } from 'react';
import style from './assets/css/nxtlabs.css';

class NXTLabs extends Component {
    state = {  } 
    render() { 
        const styles = {
            border: '5px solid red', 
       };
        return (
            <div>
                <div className="whats-new">
                    <div className="row">
                        <div className="fcol-md6">
                            <div className="ftext-white">
                                What's new at the NXT Labs
                            </div>
                            <p className="fstext-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                        </div>
                        <div className="fcol-md3">
                            <img src="./images/image.png" alt="" className="img-fluid" />
                            <div className="fs-inno">What's new at the innovation labs</div>
                            <p className="fsbase-text">Latest articles, tips and content to help create and share your experience</p>
                            <a href="javacsript:;" className="text-more hover">Find out more</a>
                        </div>
                        <div className="fcol-md3">
                            <img src="./images/image.png" alt="" className="img-fluid" />
                            <div className="fs-inno">What's new at the innovation labs</div>
                            <p className="fsbase-text">Latest articles, tips and content to help create and share your experience</p>
                            <a href="javacsript:;" className="text-more hover">Find out more</a>
                        </div>
                        <div className="fcol-md3">
                            <img src="./images/image.png" alt="" className="img-fluid" />
                            <div className="fs-inno">What's new at the innovation labs</div>
                            <p className="fsbase-text">Latest articles, tips and content to help create and share your experience</p>
                            <a href="javacsript:;" className="text-more hover">Find out more</a>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}
 
export default NXTLabs;
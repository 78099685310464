import '../Stylesheet/css/styles.css';
import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { showToastSuccessMessage, showToastErrorMessage, baseUrl, isLoading, apiKey } from '../../Constants/Constants';
import { post } from 'axios';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

export default class SharedAgenda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sharedAgendaList: [], filterType: 'P', userDetail: null
        };
    }
    componentDidMount() {
        var userdetail = localStorage.getItem("UserDetail");
        userdetail = userdetail == null ? {} : JSON.parse(userdetail);

        this.setState({
            ...this.state, userDetail: userdetail,
        }, () => {
            this.getData();
        });
    }

    getData(status = 'P') {
        fetch(baseUrl + ('/GetSharedAgenda?createdBy=' + this.state.userDetail.id + '&status=' + status + '&email=' + this.state.userDetail.mail),
            {
                headers: { 'XApiKey': apiKey }
            })
            .then(response => response.json())
            .then(records => {
                if (records) {
                    this.setState({
                        sharedAgendaList: records
                    })
                }
                isLoading(false);
            })
            .catch(error => { isLoading(false); console.log(error) })
    }

    handleClose = () => {
        this.props.onSharedAgendaPopupClose(false);
    }

    changeFilter = (type) => {
        this.setState({
            filterType: type
        });
        this.getData(type);
    }

    accept = (agendaId) => {
        this.updateStatus(agendaId, 1);
    }

    decline = (agendaId) => {
        this.updateStatus(agendaId, 2);
    }

    updateStatus(agendaId, statusType) {
        fetch(baseUrl + ('/UpdateSharedAgendaStatus?userId=' + this.state.userDetail.id + '&agendaId=' + agendaId + '&statusType=' + statusType + '&email=' + this.state.userDetail.email),
            {
                headers: { 'XApiKey': apiKey }
            })
            .then(response => response.json())
            .then(records => {
                if (response && response.data) {
                    this.setState({
                        sharedAgendaList: this.state.sharedAgendaList.filter(x => x.agendaId != agendaId)
                    })
                }
                isLoading(false);
            })
            .catch(error => { isLoading(false); console.log(error) })
    }

    render() {
        return (
            <Fragment>
                <Modal show={this.props.showSharedAgendaPopup} onHide={this.handleClose} size="lg"
                    aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="create-agenda-header">
                        <Modal.Title id="mdlSharedAgenda">
                            Shared Agenda
                            <div style={{ paddingTop: "10px", color: "white" }}>
                                <input type="radio" checked={this.state.filterType == "P"}
                                    value="P" onClick={() => this.changeFilter('P')} /> Pending
                                <input type="radio" checked={this.state.filterType == "A"} style={{ marginLeft: "10px" }}
                                    value="A" onClick={() => this.changeFilter('A')} /> Approved
                                <input type="radio" checked={this.state.filterType == "D"} style={{ marginLeft: "10px" }}
                                    value="D" onClick={() => this.changeFilter('D')} /> Declined
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="shared-agenda-popup">


                        {this.state.sharedAgendaList != null && this.state.sharedAgendaList.map((obj, index) => {
                            {
                                return (
                                    <div id={"" + index} style={{ display: "flex", marginTop: (index > 0 ? "10px" : "0px") }}>
                                        <div class="fs-6 text-white font-bold lh-base" style={{ width: "100%", paddingLeft: "10px", backgroundColor: "black", borderRadius: "5px", position: "relative" }}>
                                            <div style={{ width: "20%", float: "left", paddingTop: "7px" }}>
                                                <img width="75px" height="65px" src={obj.thumbnail} style={{ pointerEvents: "none", borderRadius: "5px", objectFit: "cover", textAlign: "left" }} />
                                            </div>
                                            <div style={{ width: "50%", float: "left", paddingLeft: "10px", textAlign: "left" }}>
                                                <div>
                                                    <div style={{ width: "36%", float: "left", paddingTop: "7px" }}> Agenda Name<label style={{ textAlign: "right" }}>:</label></div>
                                                    <div style={{ width: "64%", float: "right", paddingTop: "7px" }}> {obj.agendaName}</div>
                                                </div>
                                                <div style={{ width: "36%", float: "left" }}> Agenda Description<label style={{ textAlign: "right" }}>:</label></div>
                                                <div style={{ width: "64%", float: "right" }}> {obj.agendaDescription}</div>

                                                <div style={{ width: "36%", float: "left" }}> Created On<label style={{ textAlign: "right" }}>:</label></div>
                                                <div style={{ width: "64%", float: "right" }}> {obj.createdOn}</div>
                                            </div>
                                            {this.state.filterType == "P" && (
                                                <div style={{ paddingTop: "7px" }}>
                                                    <button style={{ backgroundColor: "#404040", color: "white", marginRight: "7px" }} type="button" className="btn btn-white" onClick={() => this.accept(obj.agendaId)}>
                                                        {/*<img src="./images/thumbs-up.png" alt="" />*/}
                                                        <span class="ps-3 pe-3" >Approve</span>
                                                    </button>

                                                    <button style={{ backgroundColor: "#404040", color: "white", marginRight: "8px" }} type="button" className="btn btn-white" onClick={() => this.decline(obj.agendaId)}>
                                                        {/*<img src="./images/thumbs-down.png" alt="" />*/}
                                                        <span class="ps-3 pe-3" >Decline</span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </Modal.Body>
                    <Modal.Footer className="create-agenda-footer">
                        <button style={{ backgroundColor: "#404040", color: "white" }} type="button" className="btn btn-white" onClick={() => this.handleClose()}>
                            Close
                        </button>
                    </Modal.Footer>
                </Modal >
            </Fragment >
        );
    }
}

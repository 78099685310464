import React from 'react';
import './index.css';
import App from './App';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

// Check if user is authenticated, and redirect if not
const checkAuthentication = async () => {
  var resp = await msalInstance.handleRedirectPromise();
  console.log(resp);
  if(resp != null){
    localStorage.setItem("access_token", resp.accessToken);
  }
  const account = await msalInstance.getAllAccounts();
  console.log(account);
  if (!account || account.length === 0 || localStorage["access_token"] === undefined) {
    msalInstance.loginRedirect();
  }
};
checkAuthentication();

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </MsalProvider>,
  document.getElementById("root")
);

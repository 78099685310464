import '../Stylesheet/css/styles.css';
import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { showToastSuccessMessage, showToastErrorMessage, baseUrl, isLoading, apiKey } from '../../Constants/Constants';
import { post } from 'axios';

export default class ClientUserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginUserDetail: null, userId: 0, userName: '', firstName: '', lastName: '', isAdmin: false
        };
    }

    componentDidMount() {
        var userdetail = localStorage.getItem("UserDetail");
        userdetail = userdetail == null ? {} : JSON.parse(userdetail);
        if (this.props != null) {
            this.setState({
                ...this.state, loginUserDetail: userdetail,
            }, () => {
                this.focusUserName.focus();
                isLoading(false);
            });
        }
    }

    changeUserName(event) {
        this.setState({ userName: event.target.value });
    }

    changeFirstName(event) {
        this.setState({ firstName: event.target.value });
    }

    changeLastName(event) {
        this.setState({ lastName: event.target.value });
    }

    changeIsAdmin(e) {
        this.setState({ isAdmin: !this.state.isAdmin });
    }

    onCloseClientUser = () => {
        this.props.onClientUserPopupClose(false);
    }

    saveUser(type = null) {
        const formData = new FormData();
        formData.append("data", JSON.stringify(this.state));
        post(baseUrl + '/CreateOrUpdateUser', formData, {
            headers: { 'XApiKey': apiKey }
        }).then((response) => {
            showToastSuccessMessage(this.state.userId > 0 ? "User Updated Successfully" : "User Added Successfully")
        })
            .catch(res => {
                showToastErrorMessage(res.response.data.message);
            });
    }

    render() {
        const enabled = (this.state.firstName.trim().length > 0 && this.state.lastName && this.state.lastName.trim().length > 0 && this.state.userName && this.state.userName.trim().length > 0);
        return (
            <Fragment>
                <Modal show={this.props.showClientUserPopup} onHide={this.onCloseClientUser}
                    aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="create-agenda-header">
                        <Modal.Title id="mdlClientUserManagement">
                            Client User Management
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="agenda-create-popup">
                        <div class="form-floating mb-3">
                            <label style={{ marginTop: "-10px", color: "#7c7c7c" }} htmlFor="txtUserName">User Name</label>
                            <input autoFocus ref={(name) => { this.focusUserName = name; }} style={{ backgroundColor: "#404040", color: "white" }} type="text" class="form-control"
                                id="txtUserName" name='txtUserName' value={this.state.userName} onChange={this.changeUserName.bind(this)} />
                        </div>
                        <div class="form-floating mb-3" style={{ marginTop: "7px" }}>
                            <label style={{ marginTop: "-10px", color: "#7c7c7c" }} htmlFor="txtFirstName">First Name</label>
                            <input style={{ backgroundColor: "#404040", color: "white" }} type="text" class="form-control"
                                id="txtFirstName" name='txtFirstName' value={this.state.firstName} onChange={this.changeFirstName.bind(this)} />
                        </div>
                        <div class="form-floating mb-3" style={{ marginTop: "7px" }}>
                            <label style={{ marginTop: "-10px", color: "#7c7c7c" }} htmlFor="txtLastName">Last Name</label>
                            <input style={{ backgroundColor: "#404040", color: "white" }} type="text" class="form-control"
                                id="txtLastName" name='txtLastName' value={this.state.lastName} onChange={this.changeLastName.bind(this)} />
                        </div>
                        <div class="form-check mb-md-0" style={{ paddingTop: "7px" }}>
                            <input class="form-check-input" type="checkbox" style={{ height: "20px", width: "20px" }} id="txtIsAdmin" value={this.state.isAdmin} checked={this.state.isAdmin} onChange={e => this.changeIsAdmin(e)} />
                            <label class="form-check-label" style={{ color: "#7c7c7c", marginTop: "7px", paddingLeft: "5px" }} htmlFor="txtIsAdmin"> IsAdmin </label>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="create-agenda-footer">
                        <button style={{ backgroundColor: "#404040", color: "white" }} disabled={!enabled} type="button" className="btn btn-white" onClick={() => this.saveUser()}>
                            {this.state.userId > 0 ? "Update" : "Create"}</button>
                        {!this.state.userId && (
                            <button style={{ backgroundColor: "#404040", color: "white", display: "none" }} disabled={!enabled} type="button" className="btn btn-white" onClick={() => this.saveUser("N")}>
                                Create & New</button>
                        )}
                    </Modal.Footer>
                </Modal >
            </Fragment >
        );
    }
}


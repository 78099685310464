import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import '../Stylesheet/css/styles.css'
import '../Stylesheet/css/bootstrap.min.css'

export default class Confirmation extends Component {
    constructor(props) {
        super(props);
    }

    handleClose() {
        this.props.onCloseConfirmation(false);
    }

    confirm() {
        this.props.confirmation_Confirm();
    }

    render() {
        return (
            <Fragment>
                <Modal show={this.props.showConfirmation} onHide={() => this.handleClose()}
                    aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header closeButton className="create-agenda-header">
                        <Modal.Title>
                            Confirmation
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ minHeight:"100px",color:"white" }}>
                        <div>
                            {this.props.popupContent}
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="create-agenda-footer">
                        <button style={{ backgroundColor: "#404040", color: "white" }} type="button" className="btn btn-white" onClick={() => this.confirm()}>
                           Yes</button>
                        <button style={{ backgroundColor: "#404040", color: "white" }} type="button" className="btn btn-white" onClick={() => this.handleClose()}>
                            No</button>
                    </Modal.Footer>
                </Modal >
            </Fragment >
        )
    }
}


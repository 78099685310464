import React, { Component } from 'react';
import useState from 'react';
import Latestupadtes from '../Home/Latestupdates';
import Featurecontent from '../Home/Featurecontent';
import SideMenu from '../Layout/SideMenu';
import { baseUrl, isLoading, apiKey } from '../../Constants/Constants';
import style from '../Home/assets/css/upperContent.css'
import { useLocation, useParams } from 'react-router-dom'
import { format } from 'date-fns';
import ReactPlayer from 'react-player'
import { PencilSquare, ArrowReturnLeft, Download } from 'react-bootstrap-icons';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default class Findoutmore extends Component
 {
    constructor(props) {
        super(props);
        this.state = { resultData: [], mediaFiles: [], conetentType: "", currentItem:"" };
    }
    componentDidMount() {
        let search = window.location.search
        let params = new URLSearchParams(search);
        this.setState({
            ...this.state, conetentType: params.get('Conetent'), currentItem: params.get('Find'),
        }, () => {
           // this.getData();
        });

    }

    
    getData() {
        if (this.state.ourLabId ) {
            fetch(this.state.ourLabId, )
                .then(response => response.json())
                .then(records => {
                    if (records) {
                        this.setState({
                            resultData: records
                        })
                    }
                    isLoading(false);
                })
                .catch(error => { isLoading(false); console.log(error) })
        }
    }
    backToOurLabs = () => {
        window.location = `Lab`;
    };
    render() {
        if (this.state.currentItem != '') {
            return (
                <div>
                    <div className="overflow">
                        <div className="navigation">
                            <SideMenu />
                        </div>
                        <div className="body-section">
                            <div className="row">
                                <div className="home-logo" style={{ backgroundColor: "#191919", position: "fixed", marginLeft: "10px" }}>
                                    <img src={process.env.PUBLIC_URL + "./images/CCEP_NXT_Horizontal_White.png"} alt="NXT" className="img-fluid" />

                                </div>
                            </div>
                            <div >
                                <div  lassName="lh-sm text-white">
                                    
                                    <button style={{ backgroundColor: "#404040", color: "white", width: "20%", float: "right", marginRight: "5%", cursor: "pointer" }} type="button" className="btn btn-white" onClick={() => this.backToOurLabs()} >
                                        <ArrowReturnLeft fontSize="20" />
                                        <span className="ps-2 pe-3">Back To Home</span>
                                    </button>
                                </div>
                                <div >
                                    <div>
                                        {(this.state.conetentType != '' && (this.state.conetentType == "File" && ((this.state.currentItem.substr(this.state.currentItem.lastIndexOf('.') + 1)) == "xls" || (this.state.currentItem.substr(this.state.currentItem.lastIndexOf('.') + 1)) == "xlsx")) ?
                                            (
                                                <iframe className={(this.state.currentItem.substr(this.state.currentItem.lastIndexOf('.') + 1))} width="99%" height="500px" frameborder="0" style={{ marginTop: "45px", marginLeft: "1%" }} src={'https://view.officeapps.live.com/op/embed.aspx?src=' + this.state.currentItem}></iframe>

                                            )
                                            :
                                            this.state.conetentType != '' && (this.state.conetentType == "File" && ((this.state.currentItem.substr(this.state.currentItem.lastIndexOf('.') + 1)) == "ppt" || (this.state.currentItem.substr(this.state.currentItem.lastIndexOf('.') + 1)) == "pptx")) ?
                                                (
                                                    <iframe className={(this.state.currentItem.substr(this.state.currentItem.lastIndexOf('.') + 1))} width="99%" height="500px" frameborder="0" style={{ marginTop: "45px", marginLeft: "1%" }} src={'https://view.officeapps.live.com/op/embed.aspx?src=' + this.state.currentItem}></iframe>
                                                )
                                                :
                                                this.state.conetentType != '' && (this.state.conetentType == "umbracoMediaArticle" && ((this.state.currentItem.substr(this.state.currentItem.lastIndexOf('.') + 1)) == "doc" || (this.state.currentItem.substr(this.state.currentItem.lastIndexOf('.') + 1)) == "docx")) ?
                                                    (
                                                        <iframe className={(this.state.currentItem.substr(this.state.currentItem.lastIndexOf('.') + 1))} width="99%" height="500px" frameborder="0" style={{ marginTop: "45px", marginLeft: "1%" }} src={'https://view.officeapps.live.com/op/embed.aspx?src=' + this.state.currentItem}></iframe>
                                                    )
                                                    :
                                                    this.state.conetentType != '' && (this.state.conetentType == "umbracoMediaArticle" || this.state.conetentType == "File" && ((this.state.currentItem.substr(this.state.currentItem.lastIndexOf('.') + 1)) == "pdf")) ?
                                                        (
                                                            /*<object data={currentSelectedItem.fileUrl} type="application/pdf" width="100%" height="100%" style={{ backgroundColor: "white" }}>  </object>*/
                                                            <iframe className={(this.state.currentItem.substr(this.state.currentItem.lastIndexOf('.') + 1))} width="99%" height="500px" frameborder="0" style={{ marginTop: "45px", marginLeft: "1%" }} src={`https://docs.google.com/gview?url=${this.state.currentItem}&embedded=true`} ></iframe>
                                                        ) :
                                                        this.state.conetentType != '' && this.state.conetentType == "umbracoMediaArticle" ?
                                                            (
                                                                <object data={this.state.currentItem} width="99%" height="500px" style={{ marginTop: "45px", marginLeft:"1%",  backgroundColor: "white" }}></object>
                                                            ) :
                                                            this.state.conetentType != '' && this.state.conetentType == "umbracoMediaVideo" ?
                                                                (
                                                                    <ReactPlayer controls={true} width='99%' height="500px" style={{ marginTop: "45px", marginLeft: "1%" }} url={this.state.currentItem} className="video-content" />
                                                                ) :
                                                                this.state.conetentType != '' && this.state.conetentType == "Url" ?
                                                                    (
                                                                        <iframe controls={true} width='99%' height="500px" style={{ marginTop: "45px", marginLeft: "1%" }} src={this.state.currentItem} />
                                                                    ) :
                                                                    this.state.conetentType != '' && (this.state.conetentType == "contentPage" || this.state.conetentType == "Image") ?
                                                                        (
                                                                            <img width="99%" height="500px" style={{ marginTop: "45px", marginLeft: "1%" }} src={this.state.currentItem} />
                                                                        ) :
                                                                        this.state.conetentType == '' && this.state.currentItem !=''?
                                                                            (
                                                                                <iframe controls={true} width='99%' height="500px" style={{ marginTop: "45px", marginLeft: "1%" }} src={this.state.currentItem} />
                                                                            )
                                                                            : (
                                                                                <img src="./images/video.png" alt="" width="99%" height="500px" style={{ marginTop: "45px", marginLeft: "1%" }} />
                                                                            )
                                        )}                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            );
        }
    }
}



import React, { Component } from 'react';
import { AzureService } from "../../AzureService"
import '../Stylesheet/css/styles.css'
import '../Stylesheet/css/bootstrap.min.css'

export default class Authentication extends Component {
    constructor(props) {
        super(props);
        window.addEventListener('storage', () => {
            if (localStorage.getItem("AuthVerifyStage") == "1"
                || localStorage.getItem("AuthVerifyStage") == "100") {
                if (localStorage.getItem("AuthVerifyStage") == "1") {
                    localStorage.setItem("AuthVerifyStage", "");
                    //AzureService.getUser();
                }
                else {
                    localStorage.setItem("AuthVerifyStage", "");
                    this.props.setAuthentication(true);
                }
            }
        });
    }

    showLogin() {
        AzureService.LoginIfRequre(this.props.openFrom);
    }

    render() {
        return (
            <div className="authentication">
                <div style={{ color: "white" }}>
                    <h2>Authentication require</h2>
                </div>
                <div onClick={() => this.showLogin()} style={{ color: "white", textDecoration: "underline", cursor: "pointer" }}>Click here to Login</div>
            </div>
        )
    }
}


import React, { Component } from 'react'

export class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Email: '',            
            formErrors: {}
        };

        this.initialState = this.state;
    }

    handleFormValidation() {
       
        const { Email } = this.state;
        let formErrors = {};
        let formIsValid = true;
        if (Email==="") {
            formIsValid = false;
            formErrors["Email"] = "Email id is required.";
        }
        else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email))) {
            formIsValid = false;
            formErrors["Email"] = "Invalid email id.";
        }
       
        this.setState({ formErrors: formErrors });
        
        return formIsValid;
    }

    handleChange = (e) => {
    
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    
    handleSubmit = (e) => {
        
        e.preventDefault();

        if (this.handleFormValidation()) {
            alert('Successfully')
            this.setState(this.initialState)
        }
    }

    render() {
        const { Email } = this.state.formErrors;
        return (
            <div class="container">
                <div class="row">
                    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div class="card border-0 shadow rounded-3 my-5">
                            <div class="card-body p-4 p-sm-5">
                                <h5 class="card-title text-center mb-5 fw-light fs-5">Forgot Password</h5>
                                <form onSubmit={this.handleSubmit}>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control" value={this.Email}
                                            onChange={this.handleChange} id="Email" name='Email' placeholder="name@example.com" /> 
                                        <div className='text-danger'>{Email}</div>
                                        <label for="Email">Email address</label>
                                    </div>

                                    <div class="d-grid">
                                        <button class="btn btn-primary btn-login text-uppercase fw-bold" type="submit">Send Email</button>
                                    </div>
                                    <div class="row mb-4 pt-4">
                                        <div class="col-md-6 d-flex justify-content-right mt-6">
                                            <a href="/Login">Back To Login</a>
                                        </div>
                                    </div>
                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPassword
import '../Stylesheet/css/styles.css';
import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { showToastSuccessMessage, showToastErrorMessage, baseUrl, apiKey } from '../../Constants/Constants';

export default class CreateTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false, tagId: (this.props != null && this.props.selectedTag != null ? this.props.selectedTag.tagId : 0),
            tagName: (this.props != null && this.props.selectedTag != null ? this.props.selectedTag.tagName : "")
        };
    }
    isShowModal = (status) => {
        this.handleClose();
        this.setState({ showModal: status });
    }
    addtagDetail(type=null) {
        var agendaTags = {
            tagID: this.state.tagId,
            tagName: this.state.tagName,
            agendaId: this.props.agendaId,
        };
        fetch(baseUrl + '/CreateAgendaTags', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'XApiKey': apiKey
            },
            body: JSON.stringify(agendaTags)
        }).then(response => response.json())
        .then((response) => {
        if (response) {
            showToastSuccessMessage("Successfully Added Tag");
            this.props.getCreatedNewTag(response);
            if (type) {
                this.focusTagName.focus();
                return this.setState({ tagId:0, tagName: "" });
            }
            this.handleClose();
        }
        else {
            this.focusTagName.focus();
            showToastErrorMessage("Something wrong");
        }
        });
    }
    deleteTag() {
        var agendaTags = {
            tagId: this.state.tagId,
            tagName: this.state.tagName,
        };
        fetch(baseUrl + '/DeleteTag', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'XApiKey': apiKey
            },
            body: JSON.stringify(agendaTags)
        }).then(response => response.json())
            .then((response) => {
                if (response) {
                    showToastSuccessMessage("Successfully deleted");
                    this.props.getDeletedTag(agendaTags);
                }
                else {
                    showToastErrorMessage("Something wrong");
                }
                this.handleClose();
            });
    }
    handleClose = () => {
        this.props.onClosePopup(false);
    }
    changeName(event) {
        this.setState({ tagName: event.target.value });
    }

    render() {
        const enabled = (this.state.tagName && this.state.tagName.trim().length > 0);
        return (
            <Fragment>
                <Modal show={this.props.showAddTagPopup} onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="create-agenda-header">
                        <Modal.Title id="mdlCreateAgenda">
                            {this.state.tagId > 0 ? "Update Tag" :"Create Tag"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ minHeight: "100px" }}>
                        <div class="form-floating mb-3">
                            <label style={{ marginTop: "-10px", color: "#7c7c7c" }} htmlFor="txtTagName">Tag Name</label>
                            <input autoFocus ref={(tagName) => { this.focusTagName = tagName;}} style={{ backgroundColor: "#404040", color: "white" }} type="text" class="form-control"
                                id="txtTagName" name='txtTagName' value={this.state.tagName} onChange={this.changeName.bind(this)} />
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="create-agenda-footer">
                        <button style={{ backgroundColor: "#404040", color: "white" }} type="button" disabled={!enabled} className="btn btn-white" onClick={() => this.addtagDetail()}>
                            {this.state.tagId > 0 ? "Update" : "Add"}
                        </button>
                        <button style={{ backgroundColor: "#404040", color: "white" }} type="button" disabled={!enabled} className="btn btn-white" onClick={() => this.addtagDetail('NEW')}>
                            {this.state.tagId > 0 ? "Update & New" : "Add & New"}
                        </button>
                        {this.state.tagId > 0 && (
                            <button style={{ backgroundColor: "#404040", color: "white" }} type="button" className="btn btn-white" onClick={() => this.deleteTag()}>
                                Delete
                            </button>
                        )}
                    </Modal.Footer>
                </Modal >

            </Fragment >
        );
    }
}
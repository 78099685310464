import React, { Component } from 'react'
import Layout from '../Layout/Layout'

export class BookNow extends Component {
  render() {
    return (
        <Layout> <p className="textcolor text-decoration-none text-center p-3">BookNow</p></Layout>
    )
  }
}

export default BookNow
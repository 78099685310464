import React, { Component } from 'react';
import UpperContent from './Components//Home/UpperContent';
import Discover from "./Components/Discover/Discover";
import { Routes, Route } from "react-router-dom"
import Agenda from "./Components/Agenda/Agenda";
import BookNow from './Components/BookNow/BookNow';
import PlayAgendaContent from "./Components/Agenda/PlayAgendaContent";
import RearrangeContent from "./Components/Agenda/RearrangeContent";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import UserRegister from "./Components/Account/UserRegister";
import Login from "./Components/Account/Login";
import ResetPassword from "./Components/Account/ResetPassword";
import ForgotPassword from "./Components/Account/ForgotPassword";
import { msalConfig } from './authConfig';
import { AzureService } from "./AzureService"
import { Circles, Triangle } from 'react-loader-spinner';
import './Components/Stylesheet/css/spinner.css'
import EventBus from 'eventing-bus';
import { baseUrl } from './Constants/Constants';
import OurLabs from './Components/OurLabs/OurLabs';
import Findoutmore from './Components/OurLabs/Findoutmore';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = { headerSearchText: "", isShowSpinner: false };
        //alert(baseUrl);
        localStorage.setItem("CCEP_Api_BaseUrl", baseUrl);
        localStorage.setItem("REACT_APP_client_id", msalConfig.clientId);
        localStorage.setItem("REACT_APP_tenantId", msalConfig.tenantId);
        //AzureService.secondValidationMethod("tets")
        //AzureService.GetRefreshToken();
        var LogeedIn = AzureService.ExpireCheck()

        if (LogeedIn == false) {
            var data = window.requestConsent();
        }        
        
    }

    isShowSpinner = (isShow) => {
        this.setState({ isShowSpinner: isShow });
    }

    componentDidMount() {
        EventBus.on("isShowSpinner", this.isShowSpinner);
    }

    changeSearchText = val => this.setState({ headerSearchText: val });



    render() {
        return (
            <div>
                <Triangle height="80" width="80" color="#4fa94d" ariaLabel="circles-loading"
                    wrapperClass="loader" visible={this.state.isShowSpinner} />
                <Routes>
                    <Route path="/" element={<UpperContent />} />
                    <Route path="Home" element={<UpperContent />} />
                    <Route path="Discover" element={<Discover />} />
                    <Route path='Agenda' element={<Agenda />} />
                    <Route path='BookNow' element={<BookNow />} />
                    <Route path="Lab" element={<UpperContent />} />
                    <Route path='UserRegister' element={<UserRegister />} />
                    <Route path='Login' element={<Login />} />
                    <Route path='ResetPassword' element={<ResetPassword />} />
                    <Route path='ForgotPassword' element={<ForgotPassword />} />
                    <Route path='PlayAgenda' element={<PlayAgendaContent />} />
                    <Route path='RearrangeContent' element={<RearrangeContent />} />
                    <Route path='OurLabs' element={<OurLabs />} />
                    <Route path='Findoutmore' element={<Findoutmore />} />
                </Routes>
            </div>
        );
    }
}

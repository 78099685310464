import '../Stylesheet/css/styles.css';
import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import LatestInnovations from '../Discover/LatestInnovations';
import Confirmation from '../Layout/Confirmation';

import EventBus from 'eventing-bus';
import { showToastSuccessMessage, showToastErrorMessage, baseUrl, isLoading, apiKey } from '../../Constants/Constants';
import { post } from 'axios';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

export default class CreateAgenda extends Component {
    constructor(props) {
        super(props);
        this.updateSelectedItem = this.updateSelectedItem.bind(this);
        this.state = {
            showModal: false, agendaName: '', agendaDescription: '', isShared: false, agendaId: 0, thumbnail: './images/video.png?width=60px&height=40px',
            mediaList: [], deleteAgendaData: null, showConfirmation: false, confirmationMsg: '', showSharedUser: false, sharedUsers: [], sharedAgendaData: { agendaId: null, agendaName: '' },
            selectedSharedUser: [], userList: []
        };
    }
    componentDidMount() {
        this.getSharedUsers();
        if (this.props != null) {
            if (this.props.editData != null && this.props.editData.agendaId > 0) {
                if (this.props.editMediaContent != null && this.props.editMediaContent.mediaFiles) {
                    this.props.editMediaContent.mediaFiles.map((obj, index) => {
                        obj.isSelected = obj.isActive > 0 ? true : false;
                    })
                    let list = JSON.stringify(this.props.editMediaContent);
                    this.setState({ mediaList: [JSON.parse(list)] });
                }

                this.setState({
                    agendaId: this.props.editData.agendaId, agendaName: this.props.editData.agendaName
                    , agendaDescription: this.props.editData.agendaDescription, thumbnail: this.props.editData.thumbnailImageObjectData,
                    sharedAgendaData: { agendaId: this.props.editData.agendaId, agendaName: this.props.editData.agendaName }
                });
            }
        }
        EventBus.on("getInitValue", this.getInitValue);
        isLoading(false, 1000);
    }

    getSharedUsers() {
        var userDetail = localStorage.getItem("UserDetail");
        userDetail = userDetail == null ? {} : JSON.parse(userDetail);
        userDetail.mail = userDetail.mail ? userDetail.mail : '';
        fetch(baseUrl + ('/GetSharedUser?loginUserEmail=' + userDetail.mail + '&agendaId=' + (this.props != null && this.props.editData != null && this.props.editData.agendaId ? this.props.editData.agendaId : 0)),
            {
                headers: { 'XApiKey': apiKey }
            })
            .then(response => response.json())
            .then(records => {
                if (records && records.userList) {
                    records.userList.map((obj, index) => {
                        this.state.userList.push({ value: obj.clientUserId, label: obj.userName });
                    });
                    this.setState({
                        userList: this.state.userList
                    })
                }
                if (records && records.sharedUserList && records.sharedUserList.length) {
                    records.sharedUserList.map((obj, index) => {
                        records.userList.map((cobj, ind) => {
                            if (obj.clientUserId == cobj.clientUserId) {
                                this.state.selectedSharedUser.push({ value: cobj.clientUserId, label: cobj.userName });
                            }
                        });
                    });

                    this.setState({
                        isShared: true,
                        showSharedUser: true,
                        selectedSharedUser: this.state.selectedSharedUser
                    })
                }
                if (records) {
                    this.setState({
                        isShared: records.isSharedAgenda ? true : false,
                        showSharedUser: records.isSharedAgenda ? true : false,
                    })
                }
                isLoading(false);
            })
            .catch(error => { isLoading(false); console.log(error) })
    }

    showThumbnail = (e) => {
        this.setState({ thumbnail: URL.createObjectURL(e.target.files[0]) })
    };

    isShowModal = (status) => {
        this.handleClose();
        this.setState({ showModal: status });
    }

    handleClose = () => {
        this.props.onPopupClose(false);
    }

    changeName(event) {
        this.setState({ agendaName: event.target.value });
    }

    changeDescription(event) {
        this.setState({ agendaDescription: event.target.value });
    }

    changeIsShared(event) {
        this.setState({ isShared: !this.state.isShared, showSharedUser: !this.state.isShared });
    }

    getInitValue = (item) => {
        this.state = { mediaList: item };
    };

    saveAgenda(type = null) {
        var userDetail = localStorage.getItem("UserDetail");
        userDetail = userDetail == null ? {} : JSON.parse(userDetail);
        var agendaDetailData = [];
        if (this.state.mediaList != null && this.state.mediaList.length) {
            var attachmentData = [];
            this.state.mediaList.map((obj, index) => {
                if (obj.mediaFiles != null && obj.mediaFiles.length > 0) {
                    attachmentData = obj.mediaFiles.filter(item => item.isSelected !== true);
                }
            })

            attachmentData.map((obj, index) => {
                agendaDetailData.push({
                    agendaId: this.state.agendaId,
                    cmSContentId: obj.contentId,
                    agendaCategoryId: obj.categoryId,
                    attachmentId: obj.id
                })
            })
        }
        let sharedUsers = [];
        if (this.state.selectedSharedUser && this.state.selectedSharedUser.length) {
            this.state.selectedSharedUser.map((obj, index) => {
                sharedUsers.push({ clientUserId: obj.value, userName: obj.label });
            });
        }
        var data = {
            id: this.state.agendaId,
            agendaName: this.state.agendaName,
            agendaDescription: this.state.agendaDescription,
            isActive: 1,
            createdBy: userDetail.id,
            userEmail: userDetail.mail,
            isShared: this.state.isShared,
            sharedUsers: sharedUsers,
        };
        var postData = {
            agenda: data,
            agendaDetail: agendaDetailData,
        }

        var imagedata = document.querySelector('input[type="file"]').files[0];
        const formData = new FormData();
        formData.append("data", JSON.stringify(postData));
        formData.append("thumbnail", imagedata);
        post(baseUrl + '/Create', formData, {
            headers: { 'XApiKey': apiKey }
        })
            //.then(response => response.json())
            .then((response) => {
                if (response && response.data) {
                    if (this.state.agendaId > 0) {
                        this.state.mediaList.map((obj, index) => {
                            if (obj.mediaFiles != null && obj.mediaFiles.length > 0) {
                                obj.mediaFiles = obj.mediaFiles.filter(item => item.isSelected == true);
                            }
                        })
                        this.setState({ mediaList: this.state.mediaList });
                        //response.data.thumbnailImageObjectData = this.state.thumbnail;
                        var sendData = {
                            mediaList: this.state.mediaList,
                            responseData: response.data
                        }

                        this.props.getUpdateMediaContent(sendData);
                    }
                    else {

                        this.props.getCreatedNewAgenda(response.data);
                    }
                    this.setState({ thumbnail: response.data.thumbnailImageObjectData });
                    showToastSuccessMessage(this.state.agendaId > 0 ? "Updated Successfully" : "Successfully Added Agenda")

                }
                if (type == "NEW") {
                    this.focusAgendaName.focus();
                    return this.setState({ agendaName: "", agendaDescription: "" });
                }
                this.handleClose();
            })
            .catch(res => {
                showToastErrorMessage(res.response.data.message);


            });
    }

    updateSelectedItem(item) {
        this.state.mediaList.map((obj, index) => {
            if (obj.mediaFiles != null && obj.mediaFiles.length > 0) {
                obj.mediaFiles.map((sobj, sindex) => {
                    if (sobj.id === item.id) {
                        sobj.isSelected = sobj.isSelected == true ? false : true;
                    }
                })
            }
        })

        this.setState({ mediaList: this.state.mediaList });
    }

    deleteAgenda = (item) => {
        this.setState({ deleteAgendaData: item, showConfirmation: true, confirmationMsg: 'Do you really want to delete?' });
    };

    confirmDelete = () => {
        fetch(baseUrl + '/DeleteAgendaAttachment', {
            method: 'POST',
            body: JSON.stringify({
                agendaId: this.state.agendaId,
                attachmentId: this.state.deleteAgendaData.id,
                cmsContentId: 0,
                agendaCategoryId: 0
            }),
            headers: { 'Content-Type': 'application/json', 'XApiKey': apiKey }
        })
            .then(response => response.json())
            .then(records => {
                if (records) {
                    showToastSuccessMessage("Media successfully deleted.");
                    this.state.mediaList.map((obj, index) => {
                        if (obj.mediaFiles != null && obj.mediaFiles.length > 0) {
                            obj.mediaFiles = obj.mediaFiles.filter(item => item.id !== this.state.deleteAgendaData.id);
                        }
                    })
                    this.setState({
                        ...this.state, mediaList: this.state.mediaList,
                    }, () => {
                        var sendData = {
                            mediaList: this.state.mediaList,
                            responseData: null
                        }
                        this.props.getUpdateMediaContent(sendData);
                        this.setState({ deleteAgendaData: null, showConfirmation: false, confirmationMsg: '' });
                    });
                }
            })
            .catch(error => console.log(error))
    }

    closeConfirmation = (status) => {
        this.setState({ deleteAgendaData: null, showConfirmation: false, confirmationMsg: '' });
    }

    onSelectUser = (items) => {
        this.setState({ selectedSharedUser: items });
    }

    render() {
        const enabled = (this.state.agendaName && this.state.agendaName.trim().length > 0);

        return (
            <Fragment>
                <Modal show={this.props.showcreatePopup} onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter" centered size={this.props.popUpSize}>
                    <Modal.Header closeButton className="create-agenda-header">
                        <Modal.Title id="mdlCreateAgenda">
                            {this.state.agendaId > 0 ? "Update agenda" : "Create new agenda"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="agenda-create-popup">
                        <div class="form-floating mb-3">
                            <label style={{ marginTop: "-10px", color: "#7c7c7c" }} htmlFor="txtAgendaName">Agenda Name</label>
                            <input autoFocus ref={(name) => { this.focusAgendaName = name; }} style={{ backgroundColor: "#404040", color: "white" }} type="text" class="form-control"
                                id="txtAgendaName" name='txtAgendaName' value={this.state.agendaName} onChange={this.changeName.bind(this)} />
                        </div>
                        <div class="form-floating mb-3" style={{ marginTop: "7px" }}>
                            <label style={{ marginTop: "-10px", color: "#7c7c7c" }} htmlFor="txtAgendaDescription">Description</label>
                            <textarea style={{ backgroundColor: "#404040", color: "white" }} type="text" class="form-control"
                                id="txtAgendaDescription" name='txtAgendaDescription' value={this.state.agendaDescription} onChange={this.changeDescription.bind(this)} />
                        </div>
                        <div class="form-check mb-md-0" style={{ paddingTop: "7px" }}>
                            <input class="form-check-input" type="checkbox" style={{ height: "20px", width: "20px" }} id="chkSharedAgenda" value={this.state.isShared} checked={this.state.isShared} onChange={e => this.changeIsShared(e)} />
                            <label class="form-check-label" style={{ color: "#7c7c7c", marginTop: "7px", paddingLeft: "5px" }} for="chkSharedAgenda"> Is Shared Agenda </label>
                        </div>
                        {this.state.showSharedUser && (
                            <div class="form-check mb-md-0 shareduser" style={{ marginTop: "10px" }}>
                                <ReactMultiSelectCheckboxes style={{ backgroundColor: "#404040", color: "white" }}
                                    placeholderButtonLabel="Shared users"
                                    options={this.state.userList}
                                    onChange={this.onSelectUser}
                                    value={this.state.selectedSharedUser}
                                />
                            </div>
                        )}

                        <div class="form-floating" style={{ marginTop: "7px" }}>
                            <label style={{ marginTop: "-10px", color: "#7c7c7c", paddingLeft: "0px" }} htmlFor="thumbnailImage">Thumbnail</label>
                        </div>
                        <div style={{ marginTop: "35px" }}>
                            <input type='file' id='thumbnailImage'
                                onChange={(event) => {
                                    this.showThumbnail(event)
                                }}
                                name='thumbnailImage' accept="image/png, image/gif, image/jpeg" style={{ backgroundColor: "#404040", color: "white", width: "65%", float: "left", marginRight: "10px" }} class="form-control" />
                            <img id='thumbnailPreview' style={{ display: 'nonex', width: "10%", height: "50px" }} src={this.state.thumbnail} />
                        </div>
                        {this.state.agendaId > 0 && (
                            <div className="media-contents-h" style={{ paddingLeft: "20px", paddingRight: "30px", paddingTop: "25px" }}>
                                <LatestInnovations folderData={this.state.mediaList} getUpdatedItem={this.updateSelectedItem.bind(this)}
                                    isHideHeader={true} comeFrom={'EDITAGENDA'} deleteAgenda={this.deleteAgenda} />
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer className="create-agenda-footer">
                        <button style={{ backgroundColor: "#404040", color: "white" }} disabled={!enabled} type="button" className="btn btn-white" onClick={() => this.saveAgenda()}>
                            {this.state.agendaId > 0 ? "Update" : "Create"}</button>
                        {!this.state.agendaId && (
                            <button style={{ backgroundColor: "#404040", color: "white", display: "none" }} disabled={!enabled} type="button" className="btn btn-white" onClick={() => this.saveAgenda("NEW")}>
                                Create & New</button>
                        )}
                    </Modal.Footer>
                </Modal >


                {this.state != null && this.state.showConfirmation == true && (
                    <Confirmation showConfirmation={this.state.showConfirmation} onCloseConfirmation={this.closeConfirmation}
                        popupContent={this.state.confirmationMsg} confirmation_Confirm={this.confirmDelete} ></Confirmation>
                )}
            </Fragment >
        );
    }
}

import React, { Component } from 'react'
import Slider from "react-slick";
import '../Stylesheet/css/styles.css';
import ReactPlayer from 'react-player';
import { baseUrl, isLoading,  apiKey } from '../../../src/Constants/Constants';

export default class Latestupdates extends Component {
    constructor(props) {
        super(props);
        this.state = { resultData: [], mediaFiles: [] };

    }

    componentDidMount() {
        this.getData();
      
    }
    getSlidesToShowCount(list) {
        return list && list.length > 5 ? 5 : list ? list.length : 0;
    }
    getData() {
        let todo = {
            "categoryId": [],
            "categoryName": [],
            "isLatestInnovation": true,
            "searchBy": "",
            "searchText": "",
            "mediaType": "",
            "sortOrder": "",
            "mediaType":  "",
            "sortOrder": "0" ,
            "filterType": "0"
        };
        fetch(baseUrl+'/api/CCEPApiService/GetHomeMediaContent', {
            method: 'POST',
            body: JSON.stringify(todo),
            headers: { 'Content-Type': 'application/json', 'XApiKey': apiKey  }
        })
            .then(response => response.json())
            .then(records => {
                if (records) {
                    this.setState({
                        resultData: records
                    })
                }
                isLoading(false);
            })
            .catch(error => { isLoading(false); console.log(error) })
    }

    categoryContent = (item) => {
        window.location = `Discover?categoryId=${item.categoryId}&fileId=${item.id}`;
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5
        };
        if (this.state.resultData != null && this.state.resultData.length > 0) {
            return (
                <div class="top-header-section w-100">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="header-Style fs-5 lh-1 text-white font-bold" style={{ marginTop: "10px", marginLeft: "25px" }}>Latest Updates</div>
                        {/*<a class="see-all-Style font-bold text-white hover" style={{*/}
                        {/*    marginRight: "2.5%", fontSize: "0.85rem!important",marginBottom :"-4px"*/}
                        {/*}}>See All</a>*/}
                    </div>
                    <div class="cursole carousel-alt pt-4" >
                        <div>
                            <Slider {...{
                            dots: true,
                            infinite: true,
                            speed: 500,
                            slidesToShow: 5,
                            slidesToScroll: 5,
                            variableWidth: true
                            }}>
                                
                            {this.state.resultData != null && this.state.resultData.map((obj, index) => {
                                console.log(this.state.resultData);
                                return (

                                    <div key={index + 1} onClick={() => this.categoryContent(obj)}>
                                        <img objectFit="cover" src={obj.fileUrl} style={{ width: "93%", borderRadius: "5px", textAlign: "left", marginLeft: "7%" }} /> 
                                        <div class="fs-6 text-white font-bold lh-base mt-2" style={{ textAlign: "center" }}>{obj.fileName}</div>
                                    </div>
                                     
                                       
                                     
                                    //<div id="carousel" className="elastislide-list">
                                    //<div  key={index + 1}>
                                    //    <div>
                                    //        <img src={obj.fileUrl} style={{ borderRadius: "5px", objectFit: "scale-down", marginLeft: "0%" }} />
                                    //    </div>
                                    //    </div>
                                    // </div>
                                )
                            })}
                                    
                             </Slider>
                        </div>
                        </div>
                </div>
            );
        }
    }
}


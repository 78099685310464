import React, { Component, Fragment } from 'react'
import Layout from '../Layout/Layout'
import Slider from "react-slick";
import '../Stylesheet/css/styles.css';
import { Modal } from 'react-bootstrap';
import PlayAgenda from './PlayAgenda';
import CreateAgenda from './CreateAgenda';
import { Heart, HeartFill, TrashFill } from 'react-bootstrap-icons';
import { showToastSuccessMessage, baseUrl, isLoading, apiKey } from '../../Constants/Constants';
import { AzureService } from "../../AzureService"
import Authentication from '../Layout/Authentication';
import { confirm } from "react-confirm-box";
import ReactTooltip from 'react-tooltip';
import SharedAgenda from './SharedAgenda';
import ClientUserManagement from './ClientUserManagement';
import { HiSearch, HiUserGroup, HiShare, HiOutlinePlusSm } from "react-icons/hi";
import { FaCopy } from "react-icons/fa";


export class Agenda extends Component {
    constructor() {
        super();
        isLoading(true);
        this.state = {
            showModalPopup: false,
            showcreatePopup: false,
            agendaId: null,
            isAuthenticated: false,
            showSharedAgendaPopup: false,
            showClientUserPopup: false
        }
    }
    componentDidMount() {
        this.checkAuthentication(AzureService.IsValidLogin(), true);
    }

    checkAuthentication(isAuth, isInit = false) {
        this.setState({
            ...this.state, isAuthenticated: isAuth
        }, () => {
            if (this.state.isAuthenticated) {
                this.getData();
            }
            else if (isInit) {
                isLoading(false);
                AzureService.LoginIfRequre("Agenda");
            }
        });
    }
    options = {
        labels: {
            confirmable: "Confirm",
            cancellable: "Cancel"
        }
    }
    deleteAgenda = async (item, value, pinvalue) => {
        const result = await confirm("Do you really want to delete this Agenda " + item.agendaName + " ?");
        if (result) {
            {
                fetch(baseUrl + '/PinAgenda', {
                    method: 'POST',
                    body: JSON.stringify({ agendaId: item.id, isPinned: pinvalue, isActive: value }),
                    headers: { 'Content-Type': 'application/json', 'XApiKey': apiKey }
                })
                    .then(response => response.json())
                    .then(records => {

                        if (records) {
                            this.state.resultData.filter(x => x.id == item.id).map((obj, index) => {
                                if (obj.id == item.id) {
                                    obj.isActive = records.isActive
                                }
                                if (obj.isActive == 0) {
                                    showToastSuccessMessage("Deleted agenda Successfully")
                                }

                            });

                            this.setState({ resultData: this.state.resultData });
                        }
                    })
                    .catch(error => console.log(error))
            }
            return;
        }
    };


    isShowPopup = (item, status, value, pinvalue, id, eve) => {
        if (eve && ((eve.target.id == id) || (eve.target.parentNode != null && eve.target.parentNode.id == id))) {
            fetch(baseUrl + '/PinAgenda', {
                method: 'POST',
                body: JSON.stringify({ agendaId: item.id, isPinned: pinvalue, isActive: value }),
                headers: { 'Content-Type': 'application/json', 'XApiKey': apiKey }
            })
                .then(response => response.json())
                .then(records => {

                    if (records) {
                        this.state.resultData.filter(x => x.id == item.id).map((obj, index) => {
                            if (obj.id == item.id) {
                                obj.isPinned = records.isPinned;
                                obj.isActive = records.isActive
                            }
                            if (obj.isActive == 0) {
                                showToastSuccessMessage("Deleted agenda Successfully")
                            }
                            else if (obj.isPinned == 1) {
                                showToastSuccessMessage("Added to pinned agenda's")
                            }
                            else if (obj.isPinned == 0) {
                                showToastSuccessMessage("Removed from pinned agenda's")
                            }

                        });

                        this.setState({ resultData: this.state.resultData });

                    }
                })
                .catch(error => console.log(error))
        }
        else {
            if (status) {
                isLoading(true);
            }
            this.setState({ agendaId: item.id, showModalPopup: status });
        }
    };

    isShowcreatePopup = (status) => {
        if (status) {
            isLoading(true);
        }
        this.setState({ showcreatePopup: status });
    }

    getData() {
        var userDetail = localStorage.getItem("UserDetail");
        userDetail = userDetail == null ? {} : JSON.parse(userDetail);
        fetch(baseUrl + ('/GetAgendaName?contentId= &categoryId= &createdBy=' + userDetail.id + '&email=' + userDetail.mail),
            {
                headers: { 'XApiKey': apiKey }
            })
            .then(response => response.json())
            .then(records => {
                if (records) {
                    this.setState({
                        resultData: records
                    })
                }
                isLoading(false);
            })
            .catch(error => { isLoading(false); console.log(error) })
    }

    UpdateAgendaItem = (item) => {
        this.state.resultData.agendaList.filter(x => x.id == item.id).map((obj, index) => {
            if (obj.id == item.id) {
                obj.agendaName = item.agendaName;
                obj.agendaDescription = item.agendaDescription;
                obj.thumbnailImageObjectData = item.thumbnailImageObjectData
            }
        });
        this.setState({ resultData: this.state.resultData });
    }

    getCreatedAgenda = (agenda) => {
        this.state.resultData = this.state.resultData == null ? {
            agendaHeader: {}, agendaList: []
        } : this.state.resultData; this.state.resultData.agendaList.push({
            id: agenda.id,
            agendaName: agenda.agendaName,
            agendaDescription: agenda.agendaDescription,
            createdDate: agenda.createdDate,
            modifiedDate: agenda.modifiedDate,
            isPinned: agenda.isPinned,
            isActive: agenda.isActive,
            thumbnailImageObjectData: agenda.thumbnailImageObjectData,
        });
        this.setState({ resultData: this.state.resultData });
    }

    getAuthentication = (isAuth) => {
        this.checkAuthentication(isAuth);
    }

    isShowSharedAgendaPopup = (status) => {
        if (status) {
            isLoading(true);
        }
        this.setState({ showSharedAgendaPopup: status });
    }

    isShowClientUserPopup = (status) => {
        if (status) {
            isLoading(true);
        }
        this.setState({ showClientUserPopup: status });
    }

    copyAgenda = async (item) => {
        var userDetail = localStorage.getItem("UserDetail");
        userDetail = userDetail == null ? {} : JSON.parse(userDetail);
        const result = await confirm("Do you really want to copy this Agenda " + item.agendaName + " ?");
        if (result) {
            {
                fetch(baseUrl + '/CopyAgenda', {
                    method: 'POST',
                    body: JSON.stringify({ agendaId: item.id, userId: userDetail.id }),
                    headers: { 'Content-Type': 'application/json', 'XApiKey': apiKey }
                })
                    .then(response => response.json())
                    .then(records => {
                        if (records) {
                            showToastSuccessMessage("Agenda Copied Successfully.");
                            this.getData();
                        }
                    })
                    .catch(error => console.log(error))
            }
            return;
        }
    };
    render() {
        document.title = 'CCEP NXT Cloud'
        return (
            <Fragment>
                <Layout>
                    {this.state.isAuthenticated === true && this.state.resultData != null &&
                        this.state.resultData.agendaHeader != null && (
                            <div class="p-4">
                                <div class="fs-2 font-bold lh-base text-white mt-2">{this.state.resultData.agendaHeader.title}</div>
                                <div class="row">
                                    <div class="col-md-8">

                                        <p class="fs-6 text-white lh-base mt-2">{this.state.resultData.agendaHeader.description}</p>
                                        <div class="d-flex laign-items-cener mt-3">
                                            <Fragment>
                                                <div>
                                                    <button type="button" class="btn btn-white" onClick={() => this.isShowcreatePopup(true)}>
                                                        <HiOutlinePlusSm style={{ fontSize: "20px" }} />
                                                        <span class="ps-2 pe-3">Create new</span>
                                                    </button>
                                                </div>
                                            </Fragment>
                                            <a href="../Discover" target="_blank" rel="noreferrer">
                                                <button type="button" class="btn btn-white ms-4">
                                                    <HiSearch style={{ fontSize: "20px" }} />
                                                    <span class="ps-2 pe-3" >Find content</span>
                                                </button>
                                            </a>
                                            {this.state.resultData.isAdminUser == true && (
                                                <Fragment>
                                                    <div style={{ paddingLeft: "20px" }}>
                                                        <button type="button" class="btn btn-white" onClick={() => this.isShowSharedAgendaPopup(true)}>
                                                            <HiShare style={{ fontSize: "20px" }} />
                                                            <span class="ps-2 pe-3">Shared Agenda</span>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )}
                                            {this.state.resultData.isAdminUser == true && (
                                                <Fragment>
                                                    <div style={{ paddingLeft: "20px" }}>
                                                        <button type="button" class="btn btn-white" onClick={() => this.isShowClientUserPopup(true)}>
                                                            <HiUserGroup style={{ fontSize: "20px" }} />
                                                            <span class="ps-2 pe-3">Client User Management</span>
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div class="fs-2 lh-base mt-4 pt-2 mb-2 text-white">Most recent Agenda's</div>
                                {this.state.resultData != null && this.state.resultData.agendaList != null && this.state.resultData.agendaList.filter(item => item.isPinned != 1 && item.isActive == 1).length > 0 && (
                                    <div class="row video-section">
                                        {this.state.resultData.agendaList.filter(item => item.isPinned != 1 && item.isActive == 1).map((obj, index) => {
                                            return (<div class="col-md-3" key={index + 1}>
                                                <div href="#" class="video position-relative d-block" >
                                                    <img src={obj.thumbnailImageObjectData} width="270" height="260" alt="" class="w-100" onClick={(e) => this.isShowPopup(obj, true, null, null, null, e)} />
                                                    <div class="fs-6 text-white font-bold lh-base mt-2">{obj.agendaName}</div>
                                                    <p class="text-white lh-base">{obj.agendaDescription}</p>
                                                    <Heart data-tip data-for="pinTip" fontSize="20" id={"Pin" + index} onClick={(e) => this.isShowPopup(obj, false, 1, 1, ("Pin" + index), e)} className="text-white top-left-pin-icon-p" />
                                                    {obj.isShared == true && (
                                                        <HiShare data-tip data-for="sharedTip" id={"Shared" + index} style={{ fontSize: "20px", marginLeft: "22px" }} className="text-white top-left-pin-icon-p" />
                                                    )}
                                                    {obj.isHaveCopyRights == true && (
                                                        <FaCopy data-tip data-for="copyAgendaTip" style={{ backgroundColor: "transparent", right: "25px" }} fontSize="20" id={"CopyAgenda" + index} onClick={(e) => this.copyAgenda(obj)} className="text-white top-right-pin-icon-p" />
                                                    )}
                                                    <TrashFill data-tip data-for="deleteTip" fontSize="20" style={{ backgroundColor: "red" }} id={"Delete" + index} onClick={(e) => this.deleteAgenda(obj, 0, 1)} className="text-white top-right-pin-icon-p" />
                                                    <ReactTooltip id="pinTip" place="top" effect="solid">
                                                        Pin Agenda
                                                    </ReactTooltip>
                                                    {obj.isShared == true && (
                                                        <ReactTooltip id="sharedTip" place="top" effect="solid">
                                                            Shared Agenda
                                                        </ReactTooltip>
                                                    )}
                                                    {obj.isHaveCopyRights == true && (
                                                        <ReactTooltip id="copyAgendaTip" place="top" effect="solid">
                                                            Copy Agenda
                                                        </ReactTooltip>
                                                    )}
                                                    <ReactTooltip id="deleteTip" place="top" effect="solid">
                                                        Delete Agenda
                                                    </ReactTooltip>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                )}

                                <div class="fs-2 lh-base mt-4 pt-2 mb-2 text-white">Pinned Agenda's</div>
                                {this.state.resultData != null && this.state.resultData.agendaList != null && this.state.resultData.agendaList.filter(item => item.isPinned == 1 && item.isActive == 1).length > 0 && (
                                    <div class="row video-section">
                                        {this.state.resultData.agendaList.filter(item => item.isPinned == 1 && item.isActive == 1).map((obj, index) => {
                                            return (<div class="col-md-3" key={index + 1}>
                                                <div href="#" class="video position-relative d-block" >
                                                    <img src={obj.thumbnailImageObjectData} alt="" class="w-100" onClick={(e) => this.isShowPopup(obj, true, null, null, null, e)} />
                                                    <div class="fs-6 text-white font-bold lh-base mt-2">{obj.agendaName}</div>
                                                    <p class="text-white lh-base">{obj.agendaDescription}</p>
                                                    <HeartFill data-tip data-for="unpin" fontSize="20" id={"UnPin" + index} onClick={(e) => this.isShowPopup(obj, false, 1, 0, ("UnPin" + index), e)} className="text-white top-left-pin-icon-p" />
                                                    {obj.isShared == true && (
                                                        <HiShare data-tip data-for="sharedTip" id={"Shared" + index} style={{ fontSize: "20px", marginLeft: "22px" }} className="text-white top-left-pin-icon-p" />
                                                    )}
                                                    {obj.isHaveCopyRights == true && (
                                                        <FaCopy data-tip data-for="copyAgendaTip" style={{ backgroundColor: "transparent", right: "25px" }} fontSize="20" id={"CopyAgenda" + index} onClick={(e) => this.copyAgenda(obj)} className="text-white top-right-pin-icon-p" />
                                                    )}
                                                    <TrashFill data-tip data-for="deleteTip" fontSize="20" style={{ backgroundColor: "red" }} id={"Delete" + index} onClick={(e) => this.deleteAgenda(obj, 0, 0)} className="text-white top-right-pin-icon-p" />
                                                    <ReactTooltip id="unpin" place="top" effect="solid">
                                                        Unpin Agenda
                                                    </ReactTooltip>
                                                    {obj.isShared == true && (
                                                        <ReactTooltip id="sharedTip" place="top" effect="solid">
                                                            Shared Agenda
                                                        </ReactTooltip>
                                                    )}
                                                    {obj.isHaveCopyRights == true && (
                                                        <ReactTooltip id="copyAgendaTip" place="top" effect="solid">
                                                            Copy Agenda
                                                        </ReactTooltip>
                                                    )}
                                                    <ReactTooltip id="deleteTip" place="top" effect="solid">
                                                        Delete Agenda
                                                    </ReactTooltip>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                    {this.state.isAuthenticated !== true && (
                        <Authentication openFrom={"Agenda"} setAuthentication={this.getAuthentication}></Authentication>
                    )}
                </Layout >
                {this.state.showcreatePopup === true && (
                    <CreateAgenda showcreatePopup={this.state.showcreatePopup} getCreatedNewAgenda={this.getCreatedAgenda} onPopupClose={this.isShowcreatePopup} ></CreateAgenda>
                )}

                {this.state.showModalPopup === true && (
                    <PlayAgenda showModalPopup={this.state.showModalPopup} onPopupClose={this.isShowPopup}
                        selectedAgendaId={this.state.agendaId} getUpdatedAgenda={this.UpdateAgendaItem} openFrom={'AGENDA'}></PlayAgenda>
                )}

                {this.state.showClientUserPopup === true && (
                    <ClientUserManagement showClientUserPopup={this.state.showClientUserPopup} onClientUserPopupClose={this.isShowClientUserPopup} ></ClientUserManagement>
                )}

                {this.state.showSharedAgendaPopup === true && (
                    <SharedAgenda showSharedAgendaPopup={this.state.showSharedAgendaPopup} onSharedAgendaPopupClose={this.isShowSharedAgendaPopup} ></SharedAgenda>
                )}

            </Fragment >
        )
    }
}

export default Agenda



import React, { Component, Fragment } from 'react';
import NXTLabs from './NXTLabs';
import Slider from "react-slick";
import '../Stylesheet/css/styles.css';
import Findoutmore from '../OurLabs/Findoutmore';
import style from './assets/css/featurecontent.css';
/*import { homeElements } from '../../../src/Constants/Constants';*/
import { baseUrl, homeElements, apiKey } from '../../../src/Constants/Constants';
import OurLabs from '../OurLabs/OurLabs';
import { Link, NavLink } from "react-router-dom";
import { Modal } from 'react-bootstrap';
class FeaturedContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            homeElements: this.props.homeElements, OurLabsElements: [], isOurLabsContent: false, isfindout: false, selectedItem:[],
        }
    }

    componentDidMount() {
       // this.getData();
        this.getourLabsData();
    }

    //getData() {
    //    fetch(baseUrl + '/api/CCEPApiService/GetHomeElements')
    //        .then(response => response.json())
    //        .then(records => {
    //            if (records) {
    //                this.setState({
    //                    homeElements: records
    //                })
    //            }
    //            if (window.location.pathname && window.location.pathname.toLowerCase() == "/lab".toLowerCase()) {
    //                setTimeout(() => {
    //                    let section = document.querySelector('#divNxtLabs');
    //                    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //                }, 1000);
    //            }
    //        })
    //        .catch(error => console.log(error))
       
    //}
    getourLabsData()
    {
        fetch(baseUrl + '/api/CCEPApiService/GetOurLabsContents', {
            headers: { 'XApiKey': apiKey }
        })
        .then(response => response.json())
        .then(records => {
            if (records) {
                this.setState({
                    OurLabsElements: records
                })
            }
            if (window.location.pathname && window.location.pathname.toLowerCase() == "/lab".toLowerCase()) {
                setTimeout(() => {
                    let section = document.querySelector('#divNxtLabs');
                    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }, 1000);
            }
        })
        .catch(error => console.log(error))
    
   }
    isShowcreatePopup = (status) => {
        if (status) {
            isLoading(true);
        }
        this.setState({ showcreatePopup: status });
    }
    ourLabsContent = (labId) => {
        this.state.isOurLabsContent = true;
        window.location = `OurLabs?OurlabId=${labId}`;
    }
    findoutmore = (value) => {
        this.state.isfindout = true;
        this.state.selectedItem = value;
        if (value != undefined && value != '')
            window.open(value, "_self");              
    }
    render() {
        document.title = 'CCEP NXT Cloud'
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4
        };
        if (this.state.homeElements != null && this.state.homeElements.howToUseElement != null) {
            console.log(this.state.homeElements)
            return (
                <Fragment>
                <div>
                    <div>
                        <div>
                            <div id="divNxtLabs" className="row" style={{ paddingBottom: "60px", paddingTop:"60px" }}>
                                <div className="col-md-4">
                                    <div className="col-md-8" style={{ paddingLeft: 16 }}>
                                        <div >
                                            <div className="lh-sm text-white mb-3" style={{ fontSize: "3rem", fontWeight: "bold" }}>{this.state.homeElements.howToUseElement.title}</div>
                                            <p className="lh-sm text-white" style={{ fontSize: "1.5rem" }}>
                                                {this.state.homeElements.howToUseElement.subTitle}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8" style={{ paddingRight: 25 }}>
                                    <div className="row bg-white rounded p-4 nxt-labs-tabs">
                                            {this.state.OurLabsElements != null && this.state.OurLabsElements.length > 0 && this.state.OurLabsElements.map((obj, index) => {
                                                return (<div class="col-md-3" style={{ padding: "10px 45px 15px 45px" }} key={index + 1} onClick={() => this.ourLabsContent(obj.id)}>
                                                    <img style={{ cursor: 'pointer' }} src={obj.thumbnail} alt="" />
                                                    <div class="mt-2" style={{ cursor: 'pointer' }}>{obj.title}</div>
                                               {/*<a href="../OurLabs" >*/}
                                               {/*     <img src="./images/carousel/11.png" alt=""/>*/}
                                               {/*         <div class="mt-2">{obj.title}</div>*/}
                                               {/*     </a>                                               */}
                                            </div>)
                                            //<p className="fs-5 lh-sm">
                                            //    {this.state.homeElements.howToUseElement.description}
                                            //</p>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div >
                            <div className="feature-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="col-md-3" style={{ }}>
                                        <div className="fs-1 text-white font-bold" style={{}}>
                                            {this.state.homeElements.featureElement.title}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="bg-white rounded px-5 py-4" style={{ height: 350 }}>
                                                <div className="fs-2 font-bold mb-3 pl-16">{this.state.homeElements.featureElement.subTitle}</div>
                                                <p className="fs-4 lh-sm" style={{ fontSize : "1.5rem" }}>
                                                {this.state.homeElements.featureElement.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>

                            <div className="whats-new" style={{ marginTop: "5rem", paddingBottom:"5rem"}}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="lh-sm text-white mb-3" style={{ paddingLeft: 16 }}>
                                            <div>
                                                <div style={{ fontSize: "3rem", fontWeight: "bold" }}>{this.state.homeElements.nxtLabsElement.title}</div>
                                                <p className="lh-sm text-white" style={{ fontSize: "1.5rem" }}>
                                                    {this.state.homeElements.nxtLabsElement.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                    <div class="container" style={{ marginLeft: "4%", marginRight: "4%", maxWidth: "92%" }}>
                                        <Slider {...{
                                            dots: true,
                                            infinite: false,
                                            speed: 500,
                                            slidesToShow: 4,
                                            slidesToScroll: 4,
                                            centerMode: false,

                                        }}>
                                                {this.state.homeElements.nxtLabsElement.nxtLabsContents != null && this.state.homeElements.nxtLabsElement.nxtLabsContents.map((msobj, sindex) => {
                                                return (
                                                    <div className="col-md-3">
                                                        <div key={sindex + 1}>
                                                            <object data={msobj.mediaFileUrl} className="img-fluid" width="95%" height="85%" style={{ borderRadius: "5px", backgroundColor: "white", marginLeft: "2%", marginRight: "2%" }}></object>
                                                            <div className="lh-sm text-white mb-3" style={{ marginLeft: "2%", fontSize: "1.5rem", fontWeight: "bold" }}>
                                                                {msobj.title}
                                                            </div>
                                                            <div className="lh-sm text-white" style={{ marginLeft: "2%",  fontSize: "1.5rem" }} >
                                                                {msobj.description}
                                                            </div>
                                                            <div
                                                                onClick={() => this.findoutmore(msobj.findOutMoreUrl)}
                                                                className="text-white fs-6 font-bold text-underline mt-5 d-block hover">
                                                                Find out more
                                                              </div>
                                                            
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                    {this.state.isOurLabsContent === true && (
                        <OurLabs selectedMediaItem={this.state.OurLabsElements} ></OurLabs>
                    )}

                    {this.state.isfindout === true && (
                        <Findoutmore selectedItem={this.state.selectedItem} ></Findoutmore>
                    )}
                </Fragment>
            )
        }
    }
}

export default FeaturedContent;

import '../Stylesheet/css/styles.css';
import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import CreateAgenda from './CreateAgenda';
import { showToastSuccessMessage, showToastErrorMessage, addAgenda } from '../../Constants/Constants';
import { baseUrl, apiKey } from '../../../src/Constants/Constants';

export default class AddAgenda extends Component {
    constructor(props) {
        super(props);
        var userDetail = localStorage.getItem("UserDetail");
        userDetail = userDetail == null ? {} : JSON.parse(userDetail);
        this.state = {
            showModal: false, agendaName: '', agendaDescription: '',
            showcreatePopup: false, resultData: [], userId: userDetail.id, email: userDetail.mail
        };
    }

    isShowModal = (status) => {
        this.handleClose();
        this.setState({ showModal: status });
    }

    handleClose = () => {
        this.props.onClosePopup(false);
    }
    componentDidMount() {
        this.getData();

    }
    getData() {
        let contentId = this.props.categoryItem && this.props.categoryItem.id > 0 ? this.props.categoryItem.id : 0;
        let categoryId = this.props.categoryItem && this.props.categoryItem.categoryId > 0 ? this.props.categoryItem.categoryId : 0
        fetch(baseUrl + ('/GetAgendaName?contentId=' + contentId + "&categoryId=" + categoryId + '&createdBy=' + this.state.userId + '&email=' + this.state.email),
            {
                headers: { 'XApiKey': apiKey }

            })
            .then(response => response.json())
            .then(records => {
                if (records) {
                    this.setState({
                        resultData: records
                    })
                }
            })
            .catch(error => console.log(error))
    }
    isShowcreatePopup = (status) => {
        this.setState({ showcreatePopup: status });
    }

    selectAgenda = (item, index) => {
        item.isSelected = item.isSelected ? false : true;
        this.setState({ resultData: this.state.resultData });
    }

    addAgendaDetail() {
        var selectedItems = this.state.resultData.agendaList.filter(agenda => {
            return agenda.isSelected == true;
        });
        if (selectedItems && selectedItems.length > 0) {
            var postData = [];
            var fileIds = [];
            var getfileUrl = "";
            if (this.props.categoryItem !=null && this.props.categoryItem.mediaFiles != null && this.props.categoryItem.mediaFiles.length > 0) {
                this.props.categoryItem.mediaFiles.filter(f => f.isSelectedAttachment == true).map((sobj, sindex) => {
                    fileIds.push(sobj.id);
                    getfileUrl = sobj.fileUrl;
                });
            }
            selectedItems.map((obj, index) => {
                postData.push({
                    agendaId: obj.id, cmSContentId: this.props.categoryItem.id,
                    cmSContentName: this.props.categoryItem.title, agendaCategoryId: this.props.categoryItem.categoryId,
                    fileId: fileIds,
                    fileUrl: getfileUrl
                });
            })

            fetch(baseUrl + '/CreateAgendaDetail', {
                method: 'POST',
                body: JSON.stringify(postData),
                headers: { 'Content-Type': 'application/json', 'XApiKey': apiKey }
            })
                .then(response => response.json())
                .then(records => {
                    if (records) {
                        showToastSuccessMessage("Successfully Added to Agenda List");
                    }
                    this.handleClose();
                })
                .catch(error => console.log(error))
        }
    }

    addNewAgenda = (agenda) => {
        this.state.resultData.agendaList = this.state.resultData.agendaList == null ? [] : this.state.resultData.agendaList;
        this.state.resultData.agendaList.push({
            id: agenda.id,
            agendaName: agenda.agendaName,
            agendaDescription: agenda.agendaDescription,
            createdDate: agenda.createdDate,
            modifiedDate: agenda.modifiedDate,
            isPinned: agenda.isPinned,
        });
        this.setState({ resultData: this.state.resultData.agendaList });
    }

    render() {
        return (
            <Fragment>
                <Modal show={this.props.showAddPopup} onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton className="create-agenda-header">
                        <Modal.Title id="mdlCreateAgenda">
                            Add to agenda
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ minHeight: "200px" }}>
                        {this.state.resultData != null && this.state.resultData.agendaList != null && this.state.resultData.agendaList.map((obj, index) => {
                            return (<div key={index + 1} className="add-agenda-padding">
                                <label style={{ color: "white" }}>
                                    <input type="checkbox" value={obj.isSelected} checked={obj.isSelected} onChange={() => this.selectAgenda(obj, index)} />
                                    <span style={{ paddingLeft: "5px" }}> {obj.agendaName}</span>
                                </label>
                            </div>
                            )
                        })}

                    </Modal.Body>
                    <Modal.Footer className="create-agenda-footer">
                        <span style={{ position: "absolute", left: "5px", cursor: "pointer" }} onClick={() => this.isShowcreatePopup(true)}><span className="add-agenda-create">+</span> Create a new agenda </span>
                        <button style={{ backgroundColor: "#404040", color: "white" }} type="button" className="btn btn-white" onClick={() => this.addAgendaDetail()}>Add</button>
                    </Modal.Footer>
                </Modal >
                {this.state.showcreatePopup === true && (
                    <CreateAgenda showcreatePopup={this.state.showcreatePopup}
                        onPopupClose={this.isShowcreatePopup} getCreatedNewAgenda={this.addNewAgenda}></CreateAgenda>
                )}
            </Fragment >
        );
    }
}

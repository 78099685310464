import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { menuItems, baseUrl, apiKey } from '../../Constants/Constants';
import axios from 'axios';
import { AzureService } from "../../AzureService"
import '../Stylesheet/css/styles.css'
import '../Stylesheet/css/bootstrap.min.css'

export class SideMenu extends Component {
    constructor(props) {
        super(props);
        const LoggedIn = AzureService.ExpireCheck();
        this.setCurrentActiveIndex = this.setCurrentActiveIndex.bind(this);
        this.showBookNow = this.showBookNow.bind(this);
        this.state = { playAgendaPath: this.getPlayAgendaPath(), activeIndex: this.getActiveTabIndex(), IsLoggedIn: LoggedIn, currentPath: window.location.pathname, BookNowUrl: "" };
    }

    async componentDidMount() {
        AzureService.AutoRefreshTokenIfRequire();
        const LoggedIn = AzureService.ExpireCheck();
        this.setState({ IsLoggedIn: LoggedIn });
        this.getBookNowUrl();
    }

    getBookNowUrl() {
        axios.get(baseUrl + '/api/BookNow/GetBookNowUrl', { headers: { 'XApiKey': apiKey, } })
            .then(response => {
                this.setState({ BookNowUrl: response.data });
            })
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    showBookNow() {
        var width = 850, height = 900;
        var left = (screen.width / 2) - (width / 2);
        var top = (screen.height / 2) - (height / 2);
        window.open(this.state.BookNowUrl, "Book Now", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left + ',noreferrer');
        var bookNowWindow = window.event.preventDefault();
        bookNowWindow.focus();
        return false;
    }

    Logout() {
        localStorage.clear();
        localStorage.setItem("expires_in", 0);
        this.setState({ IsLoggedIn: false });
        window.location.assign("https://login.windows.net/common/oauth2/logout?post_logout_redirect_uri=" + window.location.origin + "/home")

    }

    Login() {
        var data = window.requestConsent();
    }
    getActiveTabIndex() {
        return (window.location.pathname === '/' || window.location.pathname === '/Home') ? 0
            : window.location.pathname === '/Discover' ? 1
                : window.location.pathname === '/Agenda' || window.location.pathname.toLowerCase().includes("/playagenda")
                    || window.location.pathname.toLowerCase().includes("/rearrangecontent") ? 2
                    : window.location.pathname === '/BookNow' ? 3
                        : window.location.pathname === '/Lab' ? 4
                            : window.location.pathname === '/Account' ? 5
                                : 0;
    }

    getPlayAgendaPath() {
        if (window.location.pathname && (window.location.pathname.toLowerCase().includes("/playagenda") ||
            window.location.pathname.toLowerCase().includes("/rearrangecontent"))) {
            let search = window.location.search
            let params = new URLSearchParams(search);
            var agendaId = params.get('agendaId');
            if (agendaId > 0) {
                return `?agendaId=${agendaId}`
            };
        }
        return "";
    }

    setCurrentActiveIndex(index, currentPath = "") {
        //alert(index)
        let beforePath = JSON.stringify(this.state.currentPath);
        this.setState({
            ...this.state, activeIndex: index, currentPath: currentPath
        });
        beforePath = JSON.parse(beforePath);
        if (index != 3 && ((beforePath === '/' || beforePath === '/Home') || currentPath == "/Lab") ||
            ((currentPath === '/' || currentPath === '/Home') || beforePath == "/Lab")) {
            window.location.reload();
        }
    }


    render() {
        return (
            <ul className="nav list-unstyled">
                {menuItems.map((obj, index) => {
                    if (obj.title == "Book now") {
                        return (
                            <li key={index + 1} className="nav-item" onClick={() => this.setCurrentActiveIndex(index)}>
                                <a className={this.state.activeIndex === index ? "nav-active active-folder" : "nav-link"}
                                    href='#' target="popup" target="_blank" rel="noreferrer" onClick={() => this.showBookNow()}>
                                    {(obj.title == "Home" ?
                                        (<span >{obj.icon}</span>) :
                                        (<img src={obj.icon} />)
                                    )}
                                    <span>{obj.title}</span>
                                </a>

                            </li>
                        )
                    }
                    else {
                        return (
                            <li key={index + 1} className="nav-item" onClick={() => this.setCurrentActiveIndex(index, obj.href)}>
                                <Link to={((this.state.playAgendaPath && obj.href == "/Agenda")) ? this.state.playAgendaPath : obj.href} className={this.state.activeIndex === index ? (obj.title == "Our labs" ? "our-lab nav-active active-folder" : "nav-active active-folder") : "nav-link"}>
                                    {(obj.title == "Home" ?
                                        (<span>{obj.icon}</span>) :
                                        (<img src={obj.icon} />)
                                    )}
                                    <span className={this.state.activeIndex === index && obj.title == "Our labs" ? "our-lab" : ""}>{obj.title}</span>
                                </Link>
                            </li>
                        )
                    }
                })}
                <li class="nav-item" >
                    {
                        this.state.IsLoggedIn ?
                            <a class="nav-link" href="#" onClick={() => this.Logout()} >
                                <img src="./images/icons/account.svg" /><span>Logout</span>
                            </a>
                            :
                            null
                    }
                </li>
            </ul>
        )
    }
}

export default SideMenu

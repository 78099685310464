import React, { Component } from 'react';
import useState from 'react';
import Latestupadtes from '../Home/Latestupdates';
import Featurecontent from '../Home/Featurecontent';
import SideMenu from '../Layout/SideMenu';
import { baseUrl, isLoading, apiKey } from '../../../src/Constants/Constants';
import style from '../Home/assets/css/upperContent.css'
import { useLocation, useParams } from 'react-router-dom'
import { format } from 'date-fns';
import { PencilSquare, ArrowReturnLeft, Download } from 'react-bootstrap-icons';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class OurLabs extends Component {
    constructor(props) {
        super(props);
        this.state = { resultData: [], mediaFiles: [], ourLabId: 0, };
    }
    componentDidMount() {
        this.getData();

    }
    componentDidMount() {
        let search = window.location.search
        let params = new URLSearchParams(search);
        this.setState({
            ...this.state, ourLabId: params.get('OurlabId'),
        }, () => {
            this.getData();
        });
    }
    getData() {
        if (this.state.ourLabId && this.state.ourLabId > 0) {
            fetch(baseUrl + '/api/CCEPApiService/GetOurLabsContentById?contentId=' + this.state.ourLabId , {
                headers: {'XApiKey': apiKey }
            })
                .then(response => response.json())
                .then(records => {
                    if (records) {
                        this.setState({
                            resultData: records
                        })
                    }
                    isLoading(false);
                })
                .catch(error => { isLoading(false); console.log(error) })
        }
    }
    backToOurLabs = () => {
        window.location = `Lab`;
    };
    
    render() {
        if (this.state.resultData != null) {
            return (
                <div>
                    <div className="overflow">
                        <div className="navigation">
                            <SideMenu />
                        </div>
                        <div className="body-section">
                            <div className="row">
                                <div className="home-logo" style={{ backgroundColor: "#191919", position: "fixed", marginLeft: "10px" }}>
                                    <img src={process.env.PUBLIC_URL + "./images/CCEP_NXT_Horizontal_White.png"} alt="NXT" className="img-fluid" />
                                    
                                </div>
                            </div>
                            <div className="ourbanner-content" >
                                <div className="row" className="lh-sm text-white">
                                    <p style={{ fontSize: "2.5rem", marginBottom: "10px", width: "75%", float: "left" }}>
                                        {this.state.resultData.title}
                                    </p>
                                    <button style={{ backgroundColor: "#404040", color: "white", width: "20%", float: "right", marginRight:"5%", cursor: "pointer" }} type="button" className="btn btn-white" onClick={() => this.backToOurLabs()} >
                                        <ArrowReturnLeft fontSize="20" />
                                        <span className="ps-2 pe-3">Back To Home</span>
                                    </button>
                                </div>
                                <div className="row" style={{ backgroundColor: "#191919", width: "95%", height: "85%", overflowY: "auto", overflowX: "hidden", border:"2px solid white" }} >
                                    <div className="row" style={{  } }>
                                        <div className="col-md-4">
                                            <div style={{ marginTop: "5%" }}>
                                                <img src="./images/iconsdate.png" style={{ backgroundColor: "darkgray", marginLeft: "10%", height: "5%", marginRight: "5%" }} alt="" />
                                                {this.state.resultData.createdDate != null ? format(new Date(this.state.resultData.createdDate), 'dd/MM/yyyy') : ""}
                                            </div>
                                            <div style={{ marginTop: "5%" }}>
                                                <img src="./images/iconsperson.png" style={{ backgroundColor: "darkgray", marginLeft: "10%", height: "5%", marginRight: "5%" }} alt="" />
                                                {this.state.resultData.author}
                                            </div>
                                        </div>
                                        <div className="col-md-8" style={{ paddingRight: 25 }}>
                                            <div class="vl">
                                                <ul className="list-unstyled list-inline suggestion-section d-flex flex-wrap px-3 mb-2">
                                                    {this.state.resultData.tags != null && this.state.resultData.tags.map((obj, index) => {
                                                    console.log(this.state.resultData);
                                                    return (

                                                        <li className="mx-1 mt-1" style={{ paddingTop: "7px" }}>
                                                            <a className="d-block active" style={{ padding: "6px", borderRadius: "10px" }}>
                                                                <span className="ps-1 pe-1">{obj}</span>
                                                            </a>
                                                        </li>


                                                        //<div id="carousel" className="elastislide-list">
                                                        //<div  key={index + 1}>
                                                        //    <div>
                                                        //        <img src={obj.fileUrl} style={{ borderRadius: "5px", objectFit: "scale-down", marginLeft: "0%" }} />
                                                        //    </div>
                                                        //    </div>
                                                        // </div>
                                                    )
                                                })}
                                             
                                                   
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ margin: "30px" }}>
                                        <div style={{ paddingRight: "80px" }}>
                                            {ReactHtmlParser(this.state.resultData.detailedDescription)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            );
        }
    }
}

export default OurLabs;

import { House } from 'react-bootstrap-icons';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EventBus from 'eventing-bus';


const discoveryFilters = [{ id: "1", name: "Title" },
{ id: "2", name: "Author" },
{ id: "3", name: "Category" },
{ id: "4", name: "Tag" },
{ id: "5", name: "Popularty" }
]

const sortByFilters = [{ id: "1", name: "Title(A-Z)" },
{ id: "2", name: "Date(Latest)" },
{ id: "3", name: "Date(Oldest)" }
]

const menuItems = [
    { title: "Home", href: "/Home", icon: <House fontSize="30" /> },
    { title: "Discover", href: "/Discover", icon: "./images/icons/discover.svg", },
    { title: "Agenda", href: "/Agenda", icon: "./images/icons/agenda.svg", },
    { title: "Book now", href: "/BookNow", icon: "./images/icons/book_now.svg", },
    { title: "Our labs", href: "/Lab", icon: "./images/icons/our_labs.svg", },
]
//const baseUrl = "https://localhost:7254";
const baseUrl = process.env.REACT_APP_ROUTE;
const apiKey = process.env.REACT_APP_APIKEY;



//-----------For testing----------
const addAgenda = [{ id: "1", name: "Title1",isChecked:false },
    { id: "2", name: "Title2", isChecked: false },
    { id: "3", name: "Title3", isChecked: false },
    { id: "4", name: "Title4", isChecked: false },
    { id: "5", name: "Title5", isChecked: false }
]

const homeElements = 
    {
        featureElement: {

            title: "Featured Content",

            subTitle: "What's new at the NXT Labs",

            description: "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\""

        },
        newElement: {

            newElementMediaFiles: [

                {

                    mediaFileUrl: "https://www.gstatic.com/webp/gallery3/1.png",

                    mediaFileName: "Istockphoto 971711138 612X612"

                },

                {

                    mediaFileUrl: "https://www.gstatic.com/webp/gallery3/1.png",

                    mediaFileName: "Photo2"

                },

                {

                    mediaFileUrl: "https://www.gstatic.com/webp/gallery3/1.png",

                    mediaFileName: "Istockphoto 1223068182 612X612"

                },

                {

                    mediaFileUrl: "https://www.gstatic.com/webp/gallery3/1.png",

                    mediaFileName: "Facile Logo"

                }

            ],

            title: "What's new at the NXT Labs",

            subTitle: "Sub Title for the NXT Labs",

            description: ""

    },

    nxtLabsElement: {
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        subTitle: "",
        title: "What's new at the innovation labs",
        nxtLabsContents: [
            {
                title: "What's new at the NXT Labs",
                subTitle: null,
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                mediaFileUrl: 'https://ccepcms.azurewebsites.net/media/bsiifwfw/whats-new-at-the-innovation-labs.png',
                mediaFileName: "What's New At The Innovation Labs (1)",
            }
        ]
    },

        howToUseElement: {

            title: "How to use the NXT Labs",

            subTitle: "Sub Title - How to use the NXT Labs",

            description: "How to use the NXT Labs Description Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
            items: [{
                title:"Booking and visiting the labs"
            }, {
                    title: "How to use our labs"
                }, {
                    title: "How to connect to WiFi"
                }, {
                    title: "Booking catering"
                }, {
                    title: "How to use our labs"
                }, {
                title: "How to use our labs"
                }, {
                    title: "How to use our labs"
                }, {
                    title: "How to use our labs"
                }]

        }
    }


const showToastSuccessMessage = (msg) => {
    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000

    });

};
const showToastErrorMessage = (msg) => {
    toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000
    });
};

const isLoading = (isLoading, timeOut = 0) => {
    setTimeout(() => {
        EventBus.publish("isShowSpinner", isLoading);
    }, timeOut);
};

export { discoveryFilters, sortByFilters, menuItems, addAgenda, showToastErrorMessage, showToastSuccessMessage, baseUrl, homeElements, isLoading, apiKey }

import '../Stylesheet/css/styles.css';
import Layout from '../Layout/Layout'
import React, { Component, Fragment } from 'react';
import DisplayContent from './DisplayContent';
import { PencilSquare, ArrowReturnLeft, Download } from 'react-bootstrap-icons';
import { baseUrl, isLoading, apiKey } from '../../Constants/Constants';

export default class RearrangeContent extends Component {
    constructor(props) {
        super(props);
        isLoading(true);
        this.state = {
            mediaContents: [],
            selectedMediaItem: null,
            selectedContent: null,
            agendaId: null,
            showEditAgenda: false,
            agendaData: null,
            mediaList: []
        };
    }

    componentDidMount() {
        let search = window.location.search
        let params = new URLSearchParams(search);
        this.setState({
            ...this.state, agendaId: params.get('agendaId'),
        }, () => {
            this.getAgendaMediaFiles();
        });
    }

    getAgendaMediaFiles() {
        if (this.state.agendaId && this.state.agendaId > 0) {
            fetch(baseUrl + '/GetPlayAgenda?id=' + this.state.agendaId,
                {
                    headers: { 'XApiKey': apiKey }
                })
                .then(response => response.json())
                .then(records => {
                    if (records) {
                        if (records.mediaContent != null && records.mediaContent.mediaFiles != null
                            && records.mediaContent.mediaFiles.length > 0) {
                            records.mediaContent.mediaFiles[0].isSelectedAttachment = true;
                        }
                        this.setState({
                            agendaData: records,
                            selectedContent: records.mediaContent,
                            mediaContents: records.mediaContent != null ? [this.getActiveMediaOnly(JSON.stringify(records.mediaContent))] : [],
                            mediaList: records.mediaContent != null ? [records.mediaContent] : [],
                            selectedMediaItem: records.mediaContent != null && records.mediaContent.mediaFiles != null
                                && records.mediaContent.mediaFiles.length > 0 ? records.mediaContent.mediaFiles[0] : null
                        });
                    }
                    isLoading(false);
                })
                .catch(error => { isLoading(false); console.log(error) })
        }
    }

    getActiveMediaOnly(mediaContent) {
        mediaContent = JSON.parse(mediaContent);
        if (mediaContent.mediaFiles != null && mediaContent.mediaFiles.length) {
            mediaContent.mediaFiles = mediaContent.mediaFiles.filter(item => item.isActive == 1);
        }
        return mediaContent;
    }

    backToAgenda = () => {
        window.location = `Agenda`;
    };

    render() {
        const isContentExist = (this.state.mediaContents != null && this.state.mediaContents.length > 0
            && this.state.mediaContents[0].mediaFiles != null && this.state.mediaContents[0].mediaFiles.length > 0);

        return (
            <Fragment>
                <Layout>
                    <div className="modal-content">
                        <div style={{ textAlign: "right", padding: "10px", backgroundColor: "#191919" }}>
                            <button style={{ backgroundColor: "#404040", color: "white", marginLeft: "5px", cursor: "pointer" }} type="button" className="btn btn-white" onClick={() => this.backToAgenda()} >
                                <ArrowReturnLeft fontSize="20" />
                                <span className="ps-2 pe-3">Back To Agenda's</span>
                            </button>
                        </div>
                        <div style={{ backgroundColor: "#191919" }}>  
                            {isContentExist == true && (
                                <div className="media-contents-r" style={{ paddingLeft: "20px", paddingRight: "30px", marginTop: "37px", height:"100%" }}>
                                    <DisplayContent folderData={this.state.mediaContents} isHideHeader={true} isDraggable={true} comeFrom={'REARRANGECONTENT'} agendaId={this.state.agendaId}
                                        isPlaySelectedItem={true} />
                                </div>
                            )}
                        </div>
                    </div>
                </Layout >
            </Fragment >
        )
    }
}


import React, { Component } from 'react'



export class UserRegister extends Component {

    constructor(props) {
        super(props);
        this.state = {
            FirstName:'',
            LastName:'',
            Email: '',
            Password: '',
            formErrors: {}
        };

        this.initialState = this.state;
    }
    handleChange = (e) => {
       
        const { name, value } = e.target;
        this.setState({ [name]: value });
       
    }
    handleSubmit = (e) => {
        
        e.preventDefault();

        if (this.handleFormValidation()) {
            alert('You have been successfully registered.')
            this.setState(this.initialState)
        }
    }
    handleFormValidation() {
       
        const { FirstName,LastName,Email, Password } = this.state;
        let formErrors = {};
        let formIsValid = true;
        if (Email==="") {
            formIsValid = false;
            formErrors["Email"] = "Email id is required.";
        }
        else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email))) {
            formIsValid = false;
            formErrors["Email"] = "Invalid email id.";
        }
        if(Password==="")
        {
            formIsValid = false;
            formErrors["Password"] = "Password is required.";
        }
        if(FirstName==="")
        {
            formIsValid = false;
            formErrors["FirstName"] = "FirstName is required.";
        }
        if(LastName==="")
        {
            formIsValid = false;
            formErrors["LastName"] = "LastName is required.";
        }
        this.setState({ formErrors: formErrors });
        
        return formIsValid;
    }

    render() {
        const { FirstName,LastName,Email, Password } = this.state.formErrors;
        return (
            <div class="container">
                <div class="row">
                    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div class="card border-0 shadow rounded-3 my-5">
                            <div class="card-body p-4 p-sm-5">
                                <h5 class="card-title text-center mb-5 fw-light fs-5">User Profile</h5>
                                <form  onSubmit={this.handleSubmit}>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control" id="FirstName" value={this.FirstName}
                                            onChange={this.handleChange}  name="FirstName" placeholder="user" className={FirstName ? ' form-control' : 'form-control'} />  
                                        <div className='text-danger'>{FirstName}</div>
                                        <label for="FirstName">First Name</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control" id="LastName"
                                        value={this.LastName}
                                        onChange={this.handleChange} name='LastName' placeholder="Last" />
                                          <div className='text-danger'>{LastName}</div>
                                        <label for="LastName">Last Name</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control" id="Email"
                                        value={this.Email}
                                        onChange={this.handleChange} name="Email" placeholder="name@example.com" />
                                           <div className='text-danger'>{Email}</div>
                                        <label for="Email">Email address</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="password" class="form-control" id="Password"
                                         value={this.Password}
                                         onChange={this.handleChange}  name="Password" placeholder="Password" />
                                        <div className='text-danger'>{Password}</div>
                                        <label for="Password">Password</label>
                                    </div>

                                    <div class="form-check mb-3">
                                        <input class="form-check-input" type="checkbox" value="" id="rememberPasswordCheck" />
                                        <label class="form-check-label" for="rememberPasswordCheck">
                                            Remember password
                                        </label>
                                    </div>
                                    <div class="d-grid">
                                        <button class="btn btn-primary btn-login text-uppercase fw-bold" type="submit">Sign
                                            in</button>
                                    </div>

                                </form>
                                <div class="row mb-4 pt-4">
                                    <div class="col-md-6 d-flex justify-content-right mt-6">
                                        <a href="/Login">Back To Login</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserRegister
import React, { Component } from 'react'
import { debounce } from "lodash";

export class Header extends Component {
    searchHeaderFilter(evt) {
        this.debouncedSearch(evt.target.value);
    }

    changeHeaderMediaFilter(evt) {
        this.props.changeHeaderMediaTypeFilter(evt.target.value);
    }

    debouncedSearch = debounce(function (val) {
        this.props.changeSearchText(val);
    }, 1000);

    render() {
        return (
            <div class="d-flex align-items-center justify-content-between">
                <div class="w-30">
                    <img src="./images/CCEP_NXT_Horizontal_White.png" alt="NXT" class="img-fluid" />
                </div>
                <div class="w-40">
                    <div class="search">
                        <div class="d-flex align-items-center">
                            <div class="autocomplete w-100">
                                <input type="text" id="txtCommonHeaderSearch" onChange={this.searchHeaderFilter.bind(this)} class="form-control bg-transparent border-0" placeholder="Search for content..." autocomplete="off" />
                            </div>
                            <select class="selectpicker" id="sltMediaTypeHeaderFilter" onChange={this.changeHeaderMediaFilter.bind(this)}>
                                <option value="all">All</option>
                                <option value="umbracoMediaVideo">Video</option>
                                <option value="Image">Image</option>
                                <option value="Presentation">Presentation</option>
                                <option value="File">File (Document, PDF)</option>
                            </select>
                            <a href="javascript:;" class="d-inline-flex p-2">
                                <img src="./images/icons/search.svg" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <div></div>
                <div></div>
            </div>

        )
    }
}
export default Header

import jwtDecode from "jwt-decode";

var t;
export const AzureService = {
    IsValidLogin: function () {
        if (localStorage.getItem("access_token") != null && localStorage.getItem("access_token") != "") {
            return true;
        }
        else {
            return false;
        }
    },
    LoginIfRequre(openFrom="") {
        if (!this.ExpireCheck()) {
            localStorage.setItem("OpenFrom", openFrom);
            localStorage.setItem("reRoute", window.location.pathname)
            var login = window.requestConsent();
        }
        else {
            return false;
        }
    },
     GetRefreshToken: function() {
        var data = {
            "grant_type": "refresh_token",
            "code": "",
            "refresh_token": localStorage.getItem("refresh_token"),
            "client_id": localStorage.getItem("REACT_APP_client_id"),
            "tenantId": localStorage.getItem("REACT_APP_tenantId"),
            "redirect_uri": window.location.origin + "/AuthEnd.html"
         };
         const apiKey = process.env.REACT_APP_APIKEY;
         var myHeaders = new Headers();
         myHeaders.append("x-functions-key", "k0LRVnEqAPlNb/UZrKyLHsK6FWh1qqJ108scaq0VX64IYuCB3eBg==");
         myHeaders.append("Content-Type", "application/json");
         myHeaders.append("XApiKey", apiKey );
        
         fetch(localStorage.getItem("CCEP_Api_BaseUrl") + "/AzureGetTokenByTanant", {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(
                data
            )
        }).then((response) => {

            if (response.status === 200) {
                console.log(response)
                var currentDate = new Date();
                var formattecurrentDate = currentDate.toLocaleString(['en-US'], { hour12: true });
                localStorage.setItem("AccesTokenTime", formattecurrentDate);
                var res = JSON.parse(response)
                localStorage.setItem("access_token", res.access_token);
                localStorage.setItem("refresh_token", res.refresh_token);
                localStorage.setItem('expires_in', res.expires_in);
            }
        });
    },

     ExpireCheck: function () {
        let token = localStorage.getItem('access_token');
        if (token != null && token != '') {
            const { exp } = jwtDecode(token)
            const expirationTime = (exp * 1000) - 60000;
            if (Date.now() <= expirationTime) {
               
                return true;
            }
            else {
                return false;
                
            }
         }
         return false;
    },
  
    AutoRefreshTokenIfRequire: function () {
        let token = localStorage.getItem('access_token');
        if (token != null && token != '') {
            const { exp } = jwtDecode(token)
            var expirationTime = (exp * 1000) - 60000;
            if (Date.now() >= expirationTime) {
                window.getRefreshToken();
                
            }
            else {
               
                expirationTime = (expirationTime- Date.now() ) - 10000;
                setTimeout(function () { window.getRefreshToken() }, expirationTime);
            }

        }
        
        },
    getUser: function () {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " +localStorage.getItem("access_token"));
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://graph.microsoft.com/v1.0/me", requestOptions)
            .then((response) => response.json())
            .then(result => {
                if (result) {
                    localStorage.setItem("AuthVerifyStage", "100");
                    localStorage.setItem("UserDetail", JSON.stringify(result));
                    window.dispatchEvent(new Event("storage"));
                }
            })
            .catch(error => console.log('error', error));
    }
};

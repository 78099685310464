import React, { Component } from 'react'
import DiscoverContent from './DiscoverContent'
import Layout from '../Layout/Layout';



export default class Discover extends Component {
    render() {
        document.title = 'CCEP NXT Cloud'
        return (
            <Layout>
                <DiscoverContent />
            </Layout >
        )
    }
}



import React, { Component } from 'react'
import '../Stylesheet/css/styles.css'
import '../Stylesheet/css/bootstrap.min.css'

import Header from './Header'
import SideMenu from './SideMenu'
import Footer from './Footer'
import { ToastContainer } from 'react-toastify';

export const LayOutContext = React.createContext("");

export default class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = { headerSearchText: "", headerMediaType: "" };
    }

    changeSearchText = val => this.setState({ headerSearchText: val });

    changeHeaderMediaTypeFilter = val => this.setState({ headerMediaType: val });

    render() {
        return (
            <div class="d-block overflow-hidden">
                <div className="navigation">
                    {/*<div className="menu text-center">*/}
                    {/*    <a*/}
                    {/*        href="javascript:;"*/}
                    {/*        onclick="openMenu()"*/}
                    {/*        className="text-center p-3 d-block"*/}
                    {/*    >*/}
                    {/*        <img src="./images/icons/menu.svg" alt="" />*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                    <SideMenu />
                </div>
                
                <div className='body-section' style={{ background: 'black' }}>
                    <div class="header-section px-3 py-2 " >
                        <Header changeSearchText={this.changeSearchText} changeHeaderMediaTypeFilter={this.changeHeaderMediaTypeFilter} />
                    </div>
                    <LayOutContext.Provider value={this.state}>
                        {this.props.children}
                    </LayOutContext.Provider>
                    <ToastContainer />
                </div>
            </div>
           
        );
    }
}
import '../Stylesheet/css/styles.css';
import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { showToastSuccessMessage, showToastErrorMessage, baseUrl, isLoading, apiKey } from '../../Constants/Constants';
import { post } from 'axios';

export default class UploadAgendaMediaContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '', agendaId: 0, imageFile: '',
            userId: null, thumbnail: './images/default.png?width=150px&height=150px',
            isUploading: 'none',
            disableOkButton:'true'
        };
    }
    componentDidMount() {
        if (this.props != null) {
            if (this.props.agendaData != null && this.props.agendaData.agendaId > 0) {
                var userDetail = localStorage.getItem("UserDetail");
                userDetail = userDetail == null ? {} : JSON.parse(userDetail);
                this.setState({
                    agendaId: this.props.agendaData.agendaId,
                    userId: userDetail.id,
                    userEmail: userDetail.mail,
                });
            }
        }
        isLoading(false, 1000);
    }

    handleClose = () => {
        this.props.onPopupClose(false);
    }

    saveAgendaMediaFile() {
        if (this.state.title == '' || this.state.imageFile == '') {
            return;
        }

        const formData = new FormData();
        //alert(this.state.userEmail); return;
        var postData = {
            owner: this.state.userId,
            userEmail: this.state.userEmail,
            agendaId: this.state.agendaId,
            title: this.state.title,
        }
        var mediaContentFile = document.querySelector('input[id="mediaContent"]').files[0];
        var thumbnailFile = document.querySelector('input[id="thumbnailImage"]').files[0];
        formData.append("data", JSON.stringify(postData));
        formData.append("imageFile", mediaContentFile);
        formData.append("thumbnail", thumbnailFile);
        this.setState({ isUploading: "block" });
        this.setState({ disableOkButton : 'true'});
        post(baseUrl + '/UploadMediaContent', formData, {
            headers: { 'XApiKey': apiKey }
        })
            .then((response) => {
                if (response && response.data) {
                    this.setState({ isUploading: "none" });
                    this.setState({ disableOkButton: '' });
                    showToastSuccessMessage("Uploaded Successfully")
                }
                else{
                    this.setState({ isUploading: "none" });
                    this.setState({ disableOkButton: '' });            
                    showToastErrorMessage("Unable to upload media content!");                    
                }
                //isLoading(false);
                //setTimeout(() => {
                //    this.setState({ isLoadSpinner: false });
                //}, 0);
                this.handleClose();
            })
            .catch(res => {
                this.setState({ isUploading: "none" });
                this.setState({ disableOkButton: '' });            
                showToastErrorMessage("Unable to upload media content!");
            });
    }

    showThumbnail = (e) => {
        this.setState({ thumbnail: URL.createObjectURL(e.target.files[0]) });
    };

    showImage = (e) => {
        if (e.target.files[0] != undefined)
            this.setState({ imageFile: URL.createObjectURL(e.target.files[0]) });
        else
            this.setState({ imageFile: '' });

        this.enableDisableButton();
    };

    changeTitle(event) {
        this.setState({ title: event.target.value });
        console.log(this.state.title);
        this.enableDisableButton();
    }

    enableDisableButton() {
        var mediaContentFileName = '';
        if (document.querySelector('input[id="mediaContent"]').files[0] != undefined)
            mediaContentFileName = document.querySelector('input[id="mediaContent"]').files[0].name;
        
        if (this.state.title == '' || mediaContentFileName == '')
            this.setState({ disableOkButton: 'true' });
        else
            this.setState({ disableOkButton: '' });
    }

    render() {
       // alert(this.state.imageFile)
        //this.setState({ disableOkButton, this.state.title && this.state.imageFile && this.state.imageFile != './images/video.png?width=60px&height=40px'});
        //&& this.state.thumbnail && this.state.thumbnail == './images/video.png?width=60px&height=40px');

        return (
            <Fragment>
                <Modal show={this.props.showFileUploadPopup} onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header closeButton className="create-agenda-header">
                        <Modal.Title id="mdlCreateAgenda">
                            Media Upload
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="agenda-create-popup">
                        <div style={{ fontSize: "15px", color: "antiquewhite", textAlign: "justify" }} className="d-flex align-items-center">Please consider the sensitivity of any content you upload. When uploaded it will only be available for your personal agenda, however if the content is useful for others you may be asked for approval to upload for all employees.
                        </div>
                        <hr></hr>
                        <div class="form-floating mb-3">
                            <label style={{ marginTop: "-10px", color: "#7c7c7c" }} htmlFor="title">Title</label>
                            <input autoFocus style={{ backgroundColor: "#404040", color: "white" }} type="text" class="form-control"
                                id="title" name='title' value={this.state.title} onChange={this.changeTitle.bind(this)} />
                        </div>

                        <div class="form-floating mb-3">
                            <label style={{ marginTop: "-10px", color: "#7c7c7c" }} htmlFor="image">Media Content</label>
                            <div style={{ paddingLeft: "120px" }}>
                                <input type='file' id='mediaContent' onChange={(event) => { this.showImage(event) }}
                                    name='mediaContent'
                                    style={{ backgroundColor: "#404040", color: "white", width: "69%", float: "left", marginRight: "10px" }} class="form-control" />
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div class="form-floating mb-3">
                            <label style={{ marginTop: "-10px", color: "#7c7c7c" }} htmlFor="thumbnailImage">Thumbnail</label>
                            <div style={{ paddingLeft: "120px" }}>
                                <input type='file' id='thumbnailImage' onChange={(event) => { this.showThumbnail(event) }}
                                    name='thumbnailImage' accept="image/png, image/gif, image/jpeg" style={{ backgroundColor: "#404040", color: "white", width: "69%", float: "left", marginRight: "10px" }} class="form-control" />
                                <img id='thumbnailPreview' style={{ display: 'nonex', width: "12%", height: "40px" }} src={this.state.thumbnail} />
                                <div class="text-white view-video-details">The ideal thumbnail size is 240 X 160 pixels.</div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="create-agenda-footer">
                        <div id="divLoading" style={{display: this.state.isUploading, marginRight: "140px", animation: "blinker 1s linear infinite",
                            color: "antiquewhite",fontFamily: "sans-serif", fontSize : "20px"}}>Uploading file ...</div>
                        <button style={{ backgroundColor: "#404040", color: "white" }} disabled={this.state.disableOkButton} type="button" className="btn btn-white" onClick={() => this.saveAgendaMediaFile()}>
                            Ok
                        </button>
                    {/*    <div id="divLoading" style={{ display: this.state.isUploading }}>Uploading in-progress...</div>*/}
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}
